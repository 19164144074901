#product_filter {

  form {
    position: relative;
    z-index: 3;
    float: right; }

  h1 {
    display: none; }

  fieldset {
    width: 250px;
    float: left;

    label {
      width: 100px;
      line-height: 2;
      height: 30px; }

    select {
      width: 150px;
      padding: 5px 10px;
      height: 30px; } }

  .btn {
    line-height: 1;
    float: left;
    margin-left: 20px;
    width: 120px;
    height: 30px; } }
