/*######################################################################################################################
  ######################################################################################################################
  ########################################################SETTINGS######################################################
  ######################################################################################################################
  ###################################################################################################################### */
$template: 'premium';
$fontpath: '../assets/font/';
$mediapath: '../assets/';
$line-height: 24px;
$font-size: 14px;
$grid: 30px;
