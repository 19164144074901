#path {
  display: none; }

.wrp {
  width: 1500px;
  box-sizing: border-box; }



main .box-shadow,
.sidebar_content .wrp,
#order_sample .wrp,
#pw_lost .wrp,
#register .wrp,
#verify_address .wrp,
#account_tracking .wrp,
#address .wrp,
#cart_first .wrp,
#cart_second .wrp,
#cart_third .wrp,
#finish .wrp,
#product-detail .wrp,
#summary .wrp,
#upload .wrp,
#upload_section,
#finish,
#finish .wrp,
#contact .wrp,
#account_tracking .wrp {
  box-shadow: 12px 0 15px -4px rgba(0,0,0,0.25), -12px 0 20px -4px rgba(0,0,0,0.25);
  position: relative;
  z-index: 2;
  background-color: #f7f7f7; }
main,
.cms-text {
  background-color: #fff !important; }

#slider {
  .wrp {
    box-shadow: none; } }


