#modulesPrinttemplate {
  margin-bottom: 20px;
  padding: 15px;
  background: $color_white;
  h3 {
    margin: 0 0 20px 0;
    text-align: left;
    font-size: 14px;
    &:after {
      display: none; } }
  li {
    border-bottom: 1px solid lighten($color_font, 50%);
    padding-bottom: 2px!important;
    margin-bottom: 2px;
    height: 42px;
    .icon {
      float: left;
      width: 32px;
      margin: 4px 5px 0 1px;
      img {
        width: 100%;
        height: auto; } }
    a {
      display: block;
      height: 42px;
      line-height: 42px;
      text-decoration: none;
      @include transition(); }
    a:hover {
      background: lighten($color_font, 50%);
      color: $color_white;
      text-decoration: none; } }
  li:last-child {
    border-bottom: none; } }

