#headerWrapper {
  background: #fff;
  width: 100%;
  z-index: 5;
  &.sticky {
    position: fixed;
    top: 0;
    left: 0; } }

#offcanvas_open {
  display: none; }

#navbar {
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  background: rgba(237,237,237,1);
  background: -moz-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(237,237,237,1)), color-stop(53%, rgba(246,246,246,1)), color-stop(100%, rgba(255,255,255,1)));
  background: -webkit-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
  background: -o-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
  background: -ms-linear-gradient(top, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
  background: linear-gradient(to bottom, rgba(237,237,237,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#ffffff', GradientType=0 );
  color: $color_white;
  height: 50px;
  position: absolute;
  z-index: 3;
  top: 110px;
  left: 0;
  right: 0;
  box-sizing: border-box;
  &.sticky {
    position: fixed;
    top: 0; }
  li:before {
    display: none;
    margin: 0; }
  .wrp > ul {
    margin: 0;
    > li {
      position: relative;
      color: $color_blue;
      display: block;
      float: left;
      margin-left: $line-height;
      box-sizing: border-box;
      height: 50px;
      line-height: 30px;
      font-size: 18px;
      @include transition();
      &:first-child {
        cursor: pointer;
        font-size: 18px;
        padding: 10px 10px 10px 10px;
        line-height: 25px;
        height: 47px;
        margin-left: 0;
        &:after {
          float: right;
          margin-left: 5px;
          margin-top: 4px;
          font-family: 'icon';
          content: 'v'; } }
      &:nth-child(2) {
        cursor: pointer;
        font-size: 18px;
        padding: 10px 10px 10px 10px;
        line-height: 25px;
        height: 47px;
        margin-left: 0;
        &:after {
          float: right;
          margin-left: 5px;
          margin-top: 4px;
          font-family: 'icon';
          content: 'v'; }

        &:hover {
          color: $color_blue;
          @include transition(background);
          background: lighten($color_highlight, 10%); } }
      a {
        padding: 10px;
        box-sizing: border-box;
        height: 50px;
        display: block;
        font-size: 18px;
        color: $color_blue;
        @include transition(background);
        &:hover,
        .current {
          text-decoration: none;
          @include transition(background);
          background: lighten($color_highlight, 10%); }
        em {
          font-size: 12px;
          display: block; } }
      ul {
        display: none;
        position: absolute; }
      &:hover ul {
        width: 250px;
        display: block;
        background: $color_gray;
        top: 47px;
        left: 0;
        box-sizing: border-box;
        > li {
          margin: 0;
          position: static;
          a {
            box-sizing: border-box;
            width: 100%;
            font-size: 16px;
            line-height: 16px;
            color: $color_font;
            display: block;
            border: solid 1px lighten($color_font, 40%);
            border-top: none;
            padding: 10px;
            height: 50px; }


          &:hover {
            a {
              background: $color_white;
              border-right-color: $color_white; }
            ul {
              min-height: 400px;
              box-sizing: border-box;
              padding: 15px;
              display: block;
              position: absolute;
              left: 250px;
              top: 0;
              background: $color_white;
              width: 350px;
              border: solid 1px lighten($color_font, 40%);
              border-left: none;
              border-top: none;
              li {
                a {
                  display: block;
                  background: $color_gray;
                  border: none;
                  height: 30px;
                  margin-bottom: 5px;
                  border-radius: 2px;
                  line-height: 14px;
                  padding: 8px 10px;
                  &:after {
                    float: right;
                    content: '>'; }
                  &:hover {
                    background: lighten($color_highlight, 40%); } } } } }
          ul {
            display: none; } } }
      &.is--yellow {
        a {
          color: black;
          background: #fcea0f;
          background: -moz-linear-gradient(top, #fcea0f 1%, #f6bd31 100%);
          background: -webkit-linear-gradient(top, #fcea0f 1%, #f6bd31 100%);
          background: linear-gradient(to bottom, #fcea0f 1%, #f6bd31 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcea0f', endColorstr='#f6bd31',GradientType=0 );
          line-height: 32px; } } } }
  .service-menu {
    float: right;
    color: $color_white;
    .video {
      position: relative;
      display: block;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      color: $color_blue;
      padding: 0 30px 0 60px;
      background: rgb(252,234,15);
      background: -moz-linear-gradient(top, rgba(252,234,15,1) 1%, rgba(246,189,49,1) 100%);
      background: -webkit-linear-gradient(top, rgba(252,234,15,1) 1%,rgba(246,189,49,1) 100%);
      background: linear-gradient(to bottom, rgba(252,234,15,1) 1%,rgba(246,189,49,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcea0f', endColorstr='#f6bd31',GradientType=0 );
      &:before {
        content: url("../assets/custom/icon_video_nav.png");
        line-height: 50px;
        position: absolute;
        top: 10px;
        left: 20px; } }
    div {
      cursor: pointer;
      padding: 0 10px;
      height: 47px;
      margin-top: 3px;
      font-size: 18px;
      border-radius: 6px 6px 0 0;
      display: block;
      line-height: 47px;
      @include transition();
      color: $color_white;
      &:after {
        float: right;
        margin-left: 5px;
        margin-top: 4px;
        font-family: 'icon';
        content: 'v'; }
      &:hover {
        @include transition();
        color: $color_font;
        background: $color_gray;
        ul {
          display: block; } } }
    ul {
      position: absolute;
      right: 0;
      top: 50px;
      background: $color_gray;
      width: 250px;
      display: none;
      box-sizing: border-box;
      li {
        margin: 0;
        position: static;
        a {
          box-sizing: border-box;
          width: 100%;
          font-size: 16px;
          color: $color_font;
          display: block;
          border: solid 1px lighten($color_font, 40%);
          border-top: none;
          padding: 10px;
          @include transition(); }
        &:hover a {
          text-decoration: none;
          background: $color_white;
          @include transition(); } } } } }
