#payment-bar {
  background: $color_white;
  padding: 50px;
  h3 {
    margin: 0;
    font-size: 12px;
    text-transform: uppercase;
    color: #bbbbbb;
    float: left;
    width: 20%;
    br {
      line-height: 14px; }
    small {
      font-size: 14px;
      color: #000; } }
  .img {
    float: left;
    margin: 0 1.666666%;
    height: 39px;
    vertical-align: middle;
    img {
      vertical-align: middle; } } }
