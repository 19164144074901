.cart_breadcrumb {
  margin-bottom: $grid;
  width: 1160px;
  float: left;
  ul {
    display: block;
    width: 100%; }
  li {
    font-size: 18px;
    box-sizing: border-box;
    background: url($mediapath + 'cart_breadcrumb_default.png') no-repeat right center darken($color_gray, 11.5%);
    color: $color_white;
    float: left;
    padding: 15px 30px;
    height: 50px;
    position: relative;
    width: 33.333333%;
    &:before {
      display: none; }
    &.active {
      background: url($mediapath + 'cart_breadcrumb_active.png') no-repeat right center $color_blue; }
    &:last-child {
      background-image: none; }
    &.disabled {
      background: lighten(desaturate($color_blue, 25%), 10%); }
    &.preactive {
      background: url($mediapath + 'cart_breadcrumb_preactive.png') no-repeat right center #5b73ea; }
    &.unactive {
      background: url($mediapath + 'cart_breadcrumb_unactive.png') no-repeat right center #5b73ea; } } }
#cart {
  padding: 25px;
  strong,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-family: regular;
    text-align: left;
    color: $color_lila;
    &:after {
      display: none; } }
  strong {
    font-size: 14px; }
  td select {
    display: inline-block;
    width: 55px;
    height: 25px;
    padding: 3px;
    margin-right: 10px;
    option {
      line-height: 25px;
      height: 25px; } }
  section {
    strong {
      font-weight: 600;
      font-style: normal;
      color: #6f6f6f;
      font-size: 14px; }
    .icon {
      font-size: 14px;
      color: #6f6f6f; } }
  .product_calculation {
    td {
      background: $color_gray;
      padding: 5px 10px;
      strong {
        text-align: right;
        margin: 0;
        font-size: 18px;
        display: inline-block; }
      h3 {
        font-size: 18px;
        width: 100%; } } }

  .product_options strong {
    margin-top: 20px; }

  #total_netto,
  #total_coupon,
  #total_shipping,
  #total_vat,
  #total_options,
  #total_price {
    td,
    td strong {
      text-align: right; } }
  #total_shipping td {
    padding-top: 20px; }
  tr:nth-last-child(2) td {
    padding-bottom: 20px; }

  #total_price {
    background: $color_gray;
    padding: 5px 10px;
    font-size: 18px;
    color: $color_highlight;
    text-align: right;
    strong {
      color: $color_orange;
      font-family: bold;
      display: inline-block;
      font-size: 18px;
      text-align: right; } }


  #total_netto {
    td {
      background: $color_gray;
      padding: 5px 10px; } }

  #sidebar {
    width: 250px;
    float: right;
    text-align: justify;
    section {
      background: $color_white;
      margin-bottom: 20px;
      strong, h3 {
        font-weight: bold;
        font-size: 14px;
        color: $color_font;
        display: block;
        margin-bottom: 20px; }
      h3 {
        box-sizing: border-box;
        background: #e4edf4;
        height: 50px;
        line-height: 50px;
        font-size: 21px;
        font-weight: 600;
        color: $color_blue;
        margin: 0 !important;
        display: block;
        width: 100%;
        padding: 0 0 0 25px;
        text-align: left;
        font-family: 'regular';
        font-weight: 600; }

      p {
        padding: 35px 25px 35px 25px;
        font-size: 12px;
        line-height: 23px; }
      .img {
        margin: -25px 25px 15px 25px;
        img {
          width: 45%;
          display: inline-block;
          margin-bottom: 20px;
          margin-right: 2.5%; } }

      &:nth-child(2) {
         background: url($mediapath + 'cart_secure_bg.png') no-repeat bottom right $color_white; } } }


  form {
    box-sizing: border-box;
    width: 1160px;
    float: left; }
  table {
    box-sizing: border-box;
    width: 100%;
    display: block;
    background: $color_white;
    padding: 10px;
    margin-bottom: 20px; }
  tbody tr,
  > tr {
    > td {
      text-align: left;
      color: $color_font;
      line-height: 25px;
      box-sizing: border-box;
      padding: 2px 10px;
      font-size: 87.5%; }
    > th {
      font-family: bold;
      font-weight: 300;
      font-size: 18px;
      text-align: left;
      padding: 10px;
      color: $color_font; }
    th, td {
      box-sizing: border-box;
      vertical-align: top; } }
  tbody tr {
    th:first-child,
    > td:first-child {
      width: auto;
      border-right: solid 6px $color_white;
      padding-right: 4px; }
    th:nth-child(2),
    > td:nth-child(2) {
      width: 240px;
      border-right: solid 6px $color_white;
      padding-right: 4px; }
    th:nth-child(3),
    > td:nth-child(3) {
      width: 140px;
      border-right: solid 6px $color_white;
      padding-right: 4px; }
    th:nth-child(4),
    > td:nth-child(4) {
      width: 100px;
      text-align: right; } }

  #checkout_last {
    form > table {
      margin-bottom: 20px; } } }

#cart_options {
  background: $color_white;
  padding: 10px;
  margin-bottom: 80px;
  .col_left {
    float: left;
    width: 40%;
    margin-right: 1%; }
  .hint {
    margin-top: 20px; }
  .col_right {
    float: right;
    width: 49%;
    margin-left: 1%; }
  textarea {
    float: none;
    display: block;
    width: 100%;
    height: 150px; }
  .btn.success {
    width: 100%;
    float: right;
    text-align: center;
    margin-top: 40px; } }

#cart #coupon {
  td {
    padding-top: 10px; }
  button {
    margin: 0 0 0 $grid / 2; }

  input {
    width: 200px; } }

#cart_second {
  #formular {
    margin-bottom: 40px;
    .btn {
      width: 100%;
      margin-bottom: 0; }
    h3 {
      font-family: regular;
      padding: 0;
      margin: 0;
      text-align: left;
      &:after {
        display: none; } }
    fieldset {
      width: 100%; }

    input {
      width: 300px;
      float: right; }
    label {
      text-align: left;
      width: 140px; }
    .col_right {
      .btn {
        width: 300px;
        float: right; } }
    .lost_pw {
      margin-top: 10px;
      display: block;
      text-align: right;
      color: $color_lila; } } }

#cart_third {
  article {
    width: 100%; }
  #cart {
    background: $color_white; }

  table tbody tr {
    th:first-child,
    > td:first-child {
      width: auto !important; }
    th:nth-child(2),
    > td:nth-child(2) {
      width: 250px; }
    th:nth-child(3),
    > td:nth-child(3) {
      width: 180px; }
    th:nth-child(4),
    > td:nth-child(4) {
      width: 200px;
      text-align: right; } }

  .col_left,
  .col_right {
    min-height: 180px;
    position: relative;

    .icon {
      display: block;
      position: absolute;
      top: 30px;
      left: 15px;
      &:before {
        font-size: 28px; } } } }

#cart,
#cart_second,
#cart_third {
  #special {
    input {
      width: 400px; }
    button {
      float: right;
      margin-bottom: 0;
      width: 40px;
      box-sizing: border-box;
      min-width: 40px;
      padding: 0;
      .icon {
        top: 10px;
        left: 5px;
        &:before {
            color: $color_white; } } } } }

#cart_third_buy {
  label {
    width: 60px; }
  .btn.success {
    float: right;
    margin-right: 10px; } }

#cart_third_payment {
  .col_right,
  .col_left {
    label {
      width: 140px; }
    .col-2 {
      input {
        width: 115px; } } }
  .col_left {
    h3 {
      background-color: $color_blue;
      color: $color_white;
      font-size: 21px;
      font-weight: 600; }
    input, select {
      width: 100%; } }
  .col_right {
    select {
      width: 495px !important; } } }

#cart_third_agb {
  margin-bottom: 50px;
  label {
    width: 60px; }
  span {
    cursor: pointer;
    color: $color_lila; }
  fieldset {
    margin: 0;
    a {
      color: #b076a2; } } }

#cart {
  .col_left,
  .col_right {
    padding: 0;
    select {
      width: 100%; }
    hr {
      margin-bottom: 0;
      display: none; }
    p {
      padding: 50px 60px 25px 60px;
      &.hint {
        margin: 0 60px 25px 60px;
        padding: 15px;
        font-size: 12px; } }
    .content {
      padding: 0 60px 25px 60px; }
    fieldset {
      /*padding: 50px 60px 25px 60px */ }
    fieldset {
      &.coupon,
      &.versand,
      &.zahlung {
        padding: 50px 60px 25px 60px; } }


    h3 {
      background-color: $color_blue;
      color: $color_white;
      font-size: 21px;
      font-weight: 600;
      display: block;
      width: 100%;
      height: 50px;
      line-height: 50px;
      padding-left: 60px !important; } } }


#third {
  .col_left,
  .col_right {
    padding: 25px !important;
    position: relative;
    hr {
      display: block;
      margin: 24px 0; }
    p,
    address {
      padding: 0;
      font-size: 14px; }
    .hint {
      margin-left: 0px !important;
      margin-right: 0px !important; }
    .btn {
      color: #b076a2;
      &:hover {
        background: none;
        text-decoration: underline; } } }
  .icon:before {
    font-size: 25px;
    vertical-align: bottom; }
  button[name="cmd_calc"] {
    top: 0;
    &:before {
      text-indent: 5px; }
    &:hover {
      text-decoration: none !important;
      background: #b076a2 !important;
      background-color: #b076a2 !important; } } }
