#usp-bar {
  padding: 38px 0;
  margin-top: -105px;
  .wrp {
    background-color: $color_white; }
  i {
    width: 25%;
    box-sizing: border-box;
    padding: 10px 45px 0 45px;
    float: left;
    color: $color_font;
    font-size: 18px;
    line-height: 24px;
    &:before {
      font-family: 'icon';
      content: 'b';
      text-align: center;
      line-height: 55px;
      border-radius: 35px;
      border: solid 10px rgba($color_white, 0.5);
      box-sizing: border-box;
      font-size: 30px;
      color: $color_white;
      background: rgba($color_font, 0.25);
      width: 70px;
      height: 70px;
      float: left;
      margin-right: 10px;
      margin-top: -10px; }
    &.usp-quality:before {
      content: 'z'; }
    &.usp-delivery:before {
      content: 'b'; }
    &.usp-payment:before {
      content: 'k'; }
    &.usp-ecological:before {
      content: 'n'; } } }


#header_usp {
  margin-top: -75px;
  .wrp {
    background: $color_blue;
    height: 75px;
    -webkit-box-shadow: 0px -11px 33px -5px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px -11px 33px -5px rgba(0,0,0,0.25);
    box-shadow: 0px -11px 33px -5px rgba(0,0,0,0.25);
    .usp {
      box-sizing: border-box;
      width: 33.3333%;
      border-right: 1px solid;
      -webkit-border-image: -webkit-gradient(circle, 0 100%, 0 0, from(rgba(100, 120, 223, 1)), to(rgba(100, 120, 223, 0))) 1 100%;
      -webkit-border-image: -webkit-linear-gradient(bottom, rgba(100, 120, 223, 1), rgba(100, 120, 223, 0)) 1 100%;
      -moz-border-image: -moz-linear-gradient(bottom, rgba(100, 120, 223, 1), rgba(100, 120, 223, 0)) 1 100%;

      background: $color_blue;
      color: $color_yellow;
      float: left;
      height: 75px;
      line-height: 75px;
      font-size: 12px;
      font-weight: 500;
      position: relative;
      padding-left: 130px;
      &.express:before,
      &.versand:before,
      &.rechnung:before {
        line-height: 100px;
        display: block;
        width: 41px;
        height: 75px;
        left: 80px;
        position: absolute;
        text-align: right; }
      &.express:before {
        content: url(../assets/custom/icon_usp_express.png); }
      &.versand:before {
        content: url(../assets/custom/icon_usp_versand.png); }
      &.rechnung:before {
        content: url(../assets/custom/icon-usp_rechnung.png); }
      &:last-child {
        border: none; } } } }
#usp {
  float: left;
  padding-top: 25px;
  img {
    float: left;
    margin-right: 10px; } }
