.parallax {
  margin-top: 50px;
  height: 260px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  top: -125px;
  margin-bottom: -125px;
  &:before {
    content: url("../assets/custom/efre_logo.png");
    width: 320px;
    margin: 0;
    position: absolute;
    bottom: 45px;
    left: 50%;
    margin-left: -160px; }

  img {
    width: 100%;
    height: auto; } }
