$color_font: #696969;
$color_white: #fff;
$color_highlight: #f7f7f7; //#cf00a0
$color_success: #69ca00;
$color_error: #d12b0b;
$color_gray: #f7f7f7;
$color_blue: #2681c0;
$color_blue_mdd: #2681c0;
$color_yellow: #fbce4a;
$color_orange: #f29125;
$color_lila: #b076a2;
$image_prefix: 'premium';
