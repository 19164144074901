#contact {
  .col_right {
    padding: 0;
    width: 50%; }
  .col_left {
    width: 48%; }
  .upload_field {
    progress,
    .progressBar {
      width: 350px;
      margin: 0 20px 0 185px; }
    input {
      float: right;
      margin-right: 80px; }
    .btn.upload {
      clear: both;
      margin-top: 20px; } }
  .preview, .file_info {
    margin-left: 185px; } }
