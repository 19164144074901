#pageLogin {
  z-index: 3;
  width: 400px;
  margin-left: -200px;
  left: 50%;
  top: 200px;
  position: relative;
  border-radius: 5px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.25);
  box-sizing: border-box;
  padding: 20px;
  border: 1px solid #ccc;
  label {
    text-align: left; }
  h1 {
    margin-bottom: 24px;
    text-align: center; }
  input {
    width: 100%; }
  .logo {
    display: block;
    text-align: center;
    img {
      margin: 0 auto 20px auto; } }
  input[type="submit"] {
    margin-top: 20px;
    background: $color_success;
    border: 1px solid darken($color_success, 10%);
    color: $color_white; }
  .error_msg {
    padding: 10px;
    background: lighten($color_error, 53%);
    border: 1px solid lighten($color_error, 25%);
    border-radius: 3px; } }
#pageLoginBg {
  background: url("../assets/loginbg.jpg") top center no-repeat;
  background-size: cover;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: fixed; }
