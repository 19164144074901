#form_individual {
  .col_left,
  .col_right {
    img {
      width: 100%;
      height: auto;
      margin-bottom: $grid; }
    .btn.success {
      min-width: 300px;
      float: right;
      margin-right: 23px; } } }
