#related {
  background: $color_white;
  .item {
    width: 25%;
    float: left;
    box-sizing: border-box;
    padding: 10px;
    a {
      padding: 0 0 5px 0;
      display: block;
      background: $color_highlight;
      color: $color_white;
      span {
        display: block;
        padding: 0 10px;
        color: $color_white; } }
    img {
      border-bottom: solid 1px $color_gray;
      margin-bottom: 10px;
      width: 100%;
      height: auto; } } }
