@-moz-document url-prefix() {

  select {
    option {
      padding: 5px 15px; } }

  .btn.upload {
    padding: 0 10px 0 0; }

  input[type="file"] {
    height: auto !important; } }
