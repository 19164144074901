#trade-blocks {
  padding: $grid 0 30px 0;
  background: $color_white;
  .card {
    overflow: hidden;
    float: left;
    width: 370px;
    margin: 0 20px 20px 0;
    box-sizing: border-box;
    height: 255px;
    color: $color_white;
    background: $color_highlight;
    position: relative;
    z-index: 0;
    &.last-item {
      margin-right: 0; }
    img {
      z-index: 0;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: auto;
      @include transition(); }
    figcaption {
      padding: 30px;
      box-sizing: border-box;
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      height: 150px;
      bottom: -150px;
      background: rgba($color_font, 0.65);
      @include transition(); }
    &:hover {
      img {
        right: -5%;
        top: -5%;
        bottom: -5%;
        left: -5%;
        width: 110%;
        @include transition(); }
      figcaption {
        bottom: 0;
        @include transition(); } }
    h3 {
      z-index: 2;
      color: $color_font;
      position: absolute;
      top: 0;
      left: 0;
      padding: 20px 30px;
      display: block;
      font-size: 24px;
      text-transform: uppercase; }
    p {
      font-size: 18px;
      color: $color_white; }
    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      &:hover {
        text-decoration: none; } }
    &:nth-child(1) {
      h3 {
        color: $color_white; }
      figcaption {
        bottom: 0;
        background: transparent; } }
    &.double {
      width: 760px; } } }
