#uploadcheck {
  #upload_data {
    position: relative;
    background: $color_white;
    padding: 20px 20px 20px 50px;

    h3 {
      position: static;
      font-family: regular;
      padding: 0;
      margin: 0;
      font-size: 24px;
      width: 100%;
      text-align: left;
      &:after {
        display: none; }
      &:before {
        font-family: icon;
        color: $color_font;
        font-size: 32px;
        top: 20px;
        left: 10px;
        content: 'a';
        position: absolute; } }

    figure {
      clear: both;
      margin-bottom: 10px;
      img {
        border: solid 1px $color_gray;
        float: left;
        width: 120px;
        height: auto;
        margin-right: 20px; }
      figcaption {
        font-size: 14px;
        float: left; }
      &:last-of-type() {
        margin-bottom: 0; } }

    .btn.delete {
      min-width: 220px;
      margin-top: 20px; } }

  #upload_actions {
    text-align: right;
    margin: 20px 0 50px 0;
    .btn {
      margin: 0 0 0 20px; } } }
