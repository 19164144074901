.breadcrumb {
  padding: 5px 0;
  li {
    float: left;
    display: block;
    margin-right: 0;
    a {
      font-size: 14px;
      display: block;
      padding: 10px 0;
      color: $color_font;
      @include transition();
      &:after {
        font-family: 'icon';
        font-size: 14px;
        color: $color_font;
        float: right;
        margin-left: 0;
        content: 'v';
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg); } }
    &:last-child a,
    &:hover a {
        @include transition();
        color: $color_blue; }
    &:before {
      display: none; } } }
