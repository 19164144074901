.icon {
  &:before {
    font-family: icon, sans-serif;
    text-align: center; }
  &.delivery:before {
    content: 'b'; }
  &.right:before {
    content: 'r'; }
  &.delivery_address:before {
    content: '@'; }
  &.payment_address:before {
    content: 'p'; }
  &.divergent_address:before {
    content: '@'; }
  &.payment:before {
    content: 's'; }
  &.coupon:before {
    content: 'i'; }
  &.next:before {
    content: 'r'; }
  &.previous:before {
    content: 'l'; }
  &.delete:before {
    display: inline-block;
    margin-right: 5px;
    color: $color_error;
    content: url("../assets/custom/icon_trashcan.png");
    position: absolute; } }
