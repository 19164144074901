footer.main {
  background: $color_blue;
  .headline {
    color: $color_white;
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 20px; }
  a {
    text-decoration: none; }
  .distancer {
    display: inline-block;
    color: $color_white;
    font-size: 14px; }
  > .wrp {
    padding: $grid 0;
    > nav {
      float: right;
      width: auto;
      margin-left: 85px;
      &:last-of-type {
        margin-left: 0; }
      h3 {
        color: $color_white;
        font-family: 'Oswald', sans-serif;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 5px; }
      li {
        display: block;
        &:before {
          display: none; }
        a {
          display: block;
          background: transparent;
          font-size: 14px;
          color: $color_white;
          padding: 3px 0;
          position: relative;
          @include transition();
          &:hover {
            text-decoration: underline;
            text-indent: 0;
            color: $color_highlight;
            @include transition(); }
          &:after {
            position: absolute;
            top: 5px;
            right: 0;
            float: right; } }
        &:last-child a {
            border: none; } } } }
  address {
    float: left;
    color: $color_white;
    font-size: 14px;
    max-width: 500px;
    width: auto;
    margin-right: 90px;
    &:last-of-type {
      margin-right: 0; }
    .col_left,
    .col_right {
      width: 50%;
      float: left;
      color: $color_white;
      font-size: 14px;
      margin-bottom: 80px; }
    p {
      color: $color_white;
      font-size: 12px; } }
  i {
    display: block;
    color: $color_white;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 600;
    a {
      color: $color_white;
      font-weight: 600;
      &:hover {
        text-decoration: none; } }
    &:before {
      float: left;
      content: url("../assets/custom/footer_icon_phone.png");
      font-family: 'icon';
      color: rgba($color_white, .5);
      font-size: 40px;
      width: 40px;
      height: 40px;
      display: block;
      margin-right: 10px;
      margin-top: -7px; }
    &.mail:before {
      content: url("../assets/custom/footer_icon_mail.png"); } } }


#footer-bar {
  background: $color_white;
  height: 60px;
  border-bottom: 50px solid $color_blue;
  .wrp {
    font-size: 12px;
    color: $color_blue;
    line-height: 60px; }
  img {
    display: inline-block;
    margin-right: 10px; } }

#footer-product-nav {
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  &.active {
    @include transition();
    max-height: 2000px;
    height: auto; } }


#footer-product-nav_toggle {
  display: block;
  height: 30px;
  line-height: 20px;
  font-size: 14px;
  border-radius: 4px;
  background: darken($color_gray, 5%);
  padding: 5px 20px;
  color: $color_highlight; }


#totop {
  right: 45px;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  will-change: bottom;
  height: 50px;
  position: fixed;
  bottom: -50px;
  z-index: 5;
  @include transition();
  &.active {
    will-change: bottom;
    @include transition();
    bottom: 35px;
    right: 45px; }
  a {
    float: right;
    display: block;
    height: 50px;
    width: 50px;

    &:before {
      display: block;
      text-align: center;
      line-height: 50px;
      font-size: 30px;
      content: url("../assets/custom/to_top.png");
      color: $color_white;
      @include transition(); }

    &:hover {
      text-decoration: none;
      &:before {
        @include transition();
        opacity: 0.7; } } } }

.seo_shadow {
  max-width: 100%;
  margin: 0 auto; }
.seo {
  .rte_container {}
  min-height: 150px;
  font-size: 12px;
  .wrp {
    padding: 40px 200px 30px;
    box-sizing: border-box;
    h4 {
      line-height: 1em; } }
  .col-sm {
    width: 25%;
    float: left;
    box-sizing: border-box;
    padding-right: 60px; }
  .col-sm,
  .col-md {
    li {
      border-bottom: 1px dotted #dddddd;
      display: block;
      a {
        color: #23baeb;
        line-height: 3em;
        &:hover {
          text-decoration: none;
          cursor: pointer; } }
      &:hover {
        text-decoration: none;
        border-bottom: 1px dotted #23baeb; } } }

  .col-md {
    width: 50%;
    float: left;
    li {
      border-bottom: 1px dotted #dddddd;
      display: block;
      padding: 10px 0;
      a {
        color: #23baeb;
        line-height: 1.5em;
        &:hover {
          text-decoration: underline;
          cursor: pointer; } }
      &:hover {
        text-decoration: none;
        border-bottom: 1px dotted #dddddd !important; } } } }
.logo_bottom {
  max-width: 100%;
  margin: 50px auto; }

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #fcea0f;
  background: -moz-linear-gradient(top, #fcea0f 1%, #f6bd31 100%);
  background: -webkit-linear-gradient(top, #fcea0f 1%, #f6bd31 100%);
  background: linear-gradient(to bottom, #fcea0f 1%, #f6bd31 100%);
  width: 70px;
  height: 50px;
  text-decoration: none;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  border-radius: 45px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 2000;
  opacity: 0.8;
  &::after {
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMzAuNzI3IDMwLjcyNyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzAuNzI3IDMwLjcyNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxwYXRoIGQ9Ik0yLjUsMTkuMzkzYy0wLjY0LDAtMS4yNzgtMC4yNDQtMS43NjgtMC43M2MtMC45NzctMC45NzktMC45NzctMi41NjEsMC0zLjUzNUwxNS4zNjMsMC40OThsMTQuNjMsMTQuNjI5ICAgYzAuOTc4LDAuOTc1LDAuOTc4LDIuNTU5LDAsMy41MzVzLTIuNTYsMC45NzktMy41MzQsMEwxNS4zNjMsNy41NjhMNC4yNjcsMTguNjYyQzMuNzgxLDE5LjE0OCwzLjE0LDE5LjM5MywyLjUsMTkuMzkzeiAgICBNMjkuOTk0LDI5LjQ5NmMwLjk3OS0wLjk3NywwLjk3OS0yLjU1OSwwLTMuNTMzbC0xNC42My0xNC42MzJsLTE0LjYzLDE0LjYzYy0wLjk3OCwwLjk3Ny0wLjk3OCwyLjU2MSwwLDMuNTM1ICAgYzAuOTc4LDAuOTc3LDIuNTYsMC45NzcsMy41MzUsMEwxNS4zNjMsMTguNGwxMS4wOTYsMTEuMDk2YzAuNDg3LDAuNDg4LDEuMTI5LDAuNzMyLDEuNzY5LDAuNzMyICAgQzI4Ljg2OSwzMC4yMjksMjkuNTA2LDI5Ljk4NCwyOS45OTQsMjkuNDk2eiIgZmlsbD0iI0ZGRkZGRiIvPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo=);
    background-size: 26px;
    background-position: center;
    background-repeat: no-repeat; }
  &:hover {
    opacity: 1; } }
