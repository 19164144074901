#upload,
#uploadcheck,
#contact,
#address,
#summary,
#order_sample,
#cart_first,
#register,
#calculator,
#verify_address,
#cart_second,
#detail_free,
#form_individual,
#cart_third,
#account_tracking,
#newsletter,
#finish,
#fotolia,
#crop {
  padding: $grid 0;
  background: $color_gray; }

main {
  .col_left,
  .col_right,
  .col {
    background: $color_white;
    float: left;
    width: 49%;
    box-sizing: border-box;
    padding: 25px 50px;
    margin-bottom: 20px; }
  .col {
    width: 100%;
    label {
      width: 140px; }
    input,
    select,
    textarea,
    .non-input-field,
    .btn:not([name=cmd_calc]) {
      width: 300px; }
    input[type="checkbox"] {
      width: 60px;
      //QS-fix: 17.08.2015
      width: auto;
      margin-right: 0;
      float: right; }
    .col-2 {
      input {
        width: 115px; } } }


  .col_right {
    float: right;
    .col_left,
    .col_right {
      padding: 0; } }

  .col_left {
    margin-right: 2%;
    .col_left,
    .col_right {
      padding: 0; } } }

.actionbar {
  height: 60px;
  margin-bottom: 80px;
  .btn {
    padding: 0;
    line-height: 40px;
    width: 250px;
    float: left;
    margin: 0;
    text-align: center;
    font-size: 18px;
    &.success {
      float: right; } }
  p {
    float: left; } }


.align_right {
  float: right;
  &.btn {
    margin-right: 20px; } }

.align_left {
  float: left; }

.align_center {
  margin-right: auto;
  margin-left: auto; }

.text_right {
  text-align: right; }
.text_left {
  text-align: left; }
.text_center {
  text-align: justify; }

#page {
  padding: $grid 0; }

//#################################################### 2 Spaltige Formulare
#cart_second,
#contact,
#detail_free,
#account_tracking,
#finish,
#register,
#crop {
  form {
    article {
      float: none;
      width: 100%; }
    fieldset {
      float: left;
      width: 50%; }
    .col-full {
      width: 100%; }
    .col_left,
    .col_right {
      fieldset {
        float: none;
        box-sizing: border-box;
        width: 100%; } } } }

.color-error {
  color: $color_error; }

.color-hint {
  color: adjust-hue(lighten($color-error, 10%), 10%); }

.color-success {
  color: $color_success; }
