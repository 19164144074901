
section .sidebar {
  box-sizing: border-box;
  height: auto;
  overflow: hidden;
  float: left;
  background: $color_white;
  padding: 10px 10px 0 10px;
  width: 280px;
  li {
    display: block;
    a {
      color: $color_font;
      font-size: 16px;
      border-bottom: solid 1px lighten($color_font, 50%);
      display: block;
      padding: 15px 10px;
      @include transition();
      &:hover {
        text-decoration: none;
        @include transition();
        background: lighten($color_highlight, 40%);
        border-bottom-color: $color_highlight;
        color: $color_highlight; } }
    &.current a {
      background: $color_highlight;
      color: $color_white;
      border-bottom-color: $color_highlight; }

    &:last-child a {
      border: none; }
    &:before {
      display: none; } } }

.cat_listing .sidebar {
  height: auto;
  padding: 0;
  width: 250px;
  margin-right: 20px; }
