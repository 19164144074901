#fotolia {
  .wrp {
    background: $color_white;
    padding: $grid; }

  figure {
    float: left;
    width: 20%;
    min-height: 300px;
    outline: solid 1px $color_gray;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
    img {
      display: block;
      margin: 20px auto; }
    figcaption {
      position: absolute;
      top: 55%;
      left: 20px;
      right: 20px; }

    strong {
      position: relative;
      display: block;
      left: 0;
      box-sizing: border-box;
      right: 0;
      padding: 5px;
      border-radius: 2px;
      background: $color_gray;
      text-align: center; }

    ul {
      margin: 0;
      li {
        font-size: 14px;
        padding-left: 10px; } }

    &:hover {
      strong {
        background: $color_highlight;
        color: $color_white; } } }


  h1 {
    float: left;
    margin-bottom: 0; }

  #fotolia_search {
    float: right;
    fieldset {
      margin: 0; }
    .btn {
      margin-bottom: 0;
      min-width: 20px;
      padding: 0;
      margin-left: 10px;
      width: 40px;
      &:before {
        font-size: 34px;
        content: 'r';
        color: $color_white;
        font-family: icon; } } }

  #fotolia_nav {
    float: right;
    margin-bottom: $grid;
    .btn {
      float: left;
      margin: 0 0 0 10px; } } }
