//form#formular,
//form#druckmuster
form {

  h1,h2,h3,h4,h5,h6 {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 20px;
    &:after {
      width: 100%; } }

  small {
    font-size: 14px;
    display: block;
    color: lighten($color_font, 15%); }

  h2,h3,h4, h5, h6 {
    line-height: 22px;
    position: relative;
    text-align: right;
    color: $color_font;
    font-family: bold;
    font-size: 14px;
    width: 185px;
    padding-right: 17px;
    box-sizing: border-box;
    display: block;
    margin: 40px 0 20px;
    &:after {
      top: 3px;
      left: 178px;
      position: absolute;
      float: right;
      content: '';
      border-bottom: solid 1px lighten($color_font, 40%);
      margin: 10px;
      width: 350px; } }


  article {
    background: #fff;
    float: left;
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: $grid; }
  #sidebar {
    background: $color_white;
    float: right;
    width: 500px;
    box-sizing: border-box;
    padding: 30px 50px;
    strong, h1,h2,h3,h4,h5,h6 {
      text-align: left;
      &:after {
        display: none; } }
    h4 {
      strong {
        display: block; } }
    input, button.btn, a.btn, textarea {
      width: 100%;
      margin-bottom: 5px; }
    input[type="checkbox"] {
      display: inline-block;
      margin-right: 5px;
      margin-top: 5px;
      width: auto; }

    #customerlogo_billing {
      position: relative;
      img {
        display: block;
        margin: 20px auto; } } }


  table {
    width: 100%;
    td {
      width: 50%; }
    tr.total {
      color: $color_success; } }

  fieldset {
    line-height: 40px;
    color: $color_font;
    margin-bottom: 10px;
    position: relative; }
  label {
    white-space: pre;
    text-align: right;
    min-height: 1px;
    color: $color_font;
    font-size: 16px;
    float: left;
    width: 185px;
    padding-right: 17px;
    box-sizing: border-box;
    display: block;
    line-height: 40px;
    img {
      float: left;
      margin: 10px 10px 0 0; }


    small {
      color: lighten($color_font, 20%);
      font-size: 12px;
      display: block;
      line-height: 15px; } }

  input:not(.btn),
  select,
  .non-input-field,
  textarea {
    background: $color_white;
    font-family: 'regular';
    border-radius: 2px !important;
    border: solid 1px lighten($color_font, 30%);
    height: 40px;
    box-sizing: border-box;
    padding: 10px;
    color: $color_font;
    font-size: 14px;
    width: 350px;
    float: left;
    @include transition();
    &.disabled {
      background-color: transparent;
      border-color: $color_white;
      color: lighten($color_font, 20%); }
    &:focus {
      @include transition();
      background-color: $color_gray; }

    &:hover {
      @include transition();
      background-color: $color_gray;
      cursor: pointer; }
    &:focus:hover {
      cursor: text; }
    &.has-spare {
      border-radius: 2px 2px 0 0 !important; }
    &.spare {
      border-top: none;
      border-radius: 0 0 2px 2px !important; } }


  .non-input-field {
    border: none;
    background: transparent;
    height: auto;
    cursor: default;
    &:hover {
      cursor: default;
      background: $color_white; }
    input[type="checkbox"] {
      float: none !important;
      margin-top: 0 !important; } }

  input[type="checkbox"] + .non-input-field {
    width: 330px; }


  input[type="file"] {
    background: transparent;
    border: none;
    font-family: 'regular';
    width: auto;
    &:hover,
    &:active {
      background: transparent; } }
  input[name="cmd_calc"],
  button[name="cmd_calc"] {
    position: absolute;
    right: 35px;
    top: 5px;
    border-radius: 2px;
    float: right;
    width: 40px;
    min-width: 40px;
    height: 40px;
    text-indent: -9999px;
    &:before {
      font-family: 'icon';
      width: 20px;
      height: 20px;
      content: 'o';
      color: $color_white;
      display: block;
      text-indent: -4px;
      font-size: 28px;
      line-height: 22px;
      text-align: center; } }
  .measurement {
    input {
      width: 110px; }
    .value {
      display: block;
      height: 40px;
      width: 40px;
      line-height: 40px;
      float: left;
      text-align: center;
      font-size: 12px;
      color: $color_font; } }
  .col-2 input {
    width: 170px;
    &:nth-of-type(1) {
      margin-right: 10px; } }

  input[type="checkbox"] {
    cursor: pointer;
    line-height: 30px;
    width: 15px;
    height: 15px;
    margin-top: 13px;
    margin-right: 10px; }

  select {
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background: url($mediapath + 'elements_select.png') no-repeat 98% center $color_gray;
    background-size: 25px 25px;
    option {
      cursor: pointer; } }
  textarea {
    height: 220px;
    -webkit-resize: none;
    -moz-resize: none;
    -ms-resize: none;
    resize: none; }

  > .btn:not([name="cmd_calc"]),
  article .btn:not([name="cmd_calc"]),
  > section:not(#total_block) > .btn:not([name="cmd_calc"]) {
    width: 350px;
    margin-left: 185px;
    margin-bottom: 10px; }

  .requirement,
  .suffix {
    color: $color_font;
    line-height: 40px;
    display: block;
    float: left;
    width: 10px;
    font-size: 18px;
    margin-left: 10px; }

  .suffix {
    font-size: 14px;
    color: lighten($color_font, 15%); }

  .info {
    cursor: help;
    position: relative;
    display: block;
    float: left;
    width: 20px;
    height: 40px;
    &:before {
      display: block;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 20px;
      font-family: 'icon';
      content: '?';
      color: lighten($color_font, 20%); }
    &:hover:before {
      color: $color_highlight; }
    .tooltip {
      cursor: text;
      left: 40px;
      top: 0;
      min-width: 220px;
      max-width: 360px;
      @include transition();
      position: absolute;
      z-index: 1;
      padding: 7px 10px;
      border-radius: 2px;
      border: solid 1px lighten($color_font, 40%);
      box-shadow: 0 0 6px rgba(0,0,0,.15);
      visibility: hidden;
      opacity: 0;
      background: $color_white;
      strong {
        font-size: 14px; }
      p {
        margin: 0;
        font-size: 12px;
        line-height: 18px; }
      ul {
        margin-bottom: 5px;
        li {
          font-size: 12px;
          line-height: 18px; } } }
    &:hover {
      .tooltip {
        @include transition();
        opacity: 1;
        visibility: visible; } } } }

#preloader {
  right: 20px;
  bottom: 0px;
  display: none;
  position: fixed;
  width: 20px;
  height: 20px;
  padding: 20px;
  &.active {
    display: block; }
  .anim.loading {
    border-bottom-color: $color_white; } }

#delivery_address,
#divergent_address {
  clear: both;
  margin-top: 60px; }
