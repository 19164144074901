#popup_offer,
#popup_recommend {
  h1 {
    margin-bottom: 24px; }
  .btn.success {
    margin-top: 20px; }
  section {
    padding: 15px;
    background: $color_gray;
    .btn {
      margin-left: 0 !important; }
    .btn,
    input {
      width: 100% !important; } } }

#popup_recommend {
  form {
    width: 800px;
    fieldset {
      width: 50%;
      float: left; } } }

#ds_popup_overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.8);
  position: fixed;
  z-index: 10;
  -webkit-animation: fadeIn 0.3s 1 ease;
  -moz-animation: fadeIn 0.3s 1 ease;
  animation: fadeIn 0.3s 1 ease;
  @include transition(opacity);
  &:after {
    top: 50%;
    left: 50%;
    content: '';
    display: block;
    font-size: 10px;
    position: absolute;
    border-radius: 100px;
    width: $font-size;
    height: $font-size;
    text-indent: -9999em;
    border: solid 3px $color_white;
    border-bottom-color: transparent;
    -webkit-animation: loader 1.1s infinite linear;
    -moz-animation: loader 1.1s infinite linear;
    animation: loader 1.1s infinite linear; }
  &:before {
    cursor: pointer;
    content: 'x';
    position: absolute;
    top: 20px;
    font-family: 'icon';
    right: 20px;
    color: $color_white;
    font-size: 200%; }

  &.closing {
    @include transition(opacity);
    opacity: 0; } }

#ds_popup {
  max-height: 70%;
  overflow: hidden;
  min-width: 350px;
  padding: 15px;
  background: $color_white;
  position: fixed;
  z-index: 11;
  left: 50%;
  top: 50%;
  border-radius: 6px;
  border: solid 1px $color_gray;
  -webkit-animation: popIn 0.5s 1 ease;
  -moz-animation: popIn 0.5s 1 ease;
  animation: popIn 0.5s 1 ease;
  @include transition(opacity);
  &.closing {
    @include transition(opacity);
    opacity: 0;
    top: -100%; }

  .btn {
    margin-bottom: 5px; } }

