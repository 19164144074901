.trade-groups,
.product-listing {
  //background: $color_gray
  .content {
    float: left;
    width: 870px; }
  .card {
    box-sizing: border-box;
    position: relative;
    float: left;
    width: 270px;
    margin: 0 0 20px 20px;
    overflow: hidden;
    min-height: 200px;
    img {
      width: 100%;
      height: auto; }
    figcaption {
      position: absolute;
      bottom: -40px;
      padding: 15px 10px 10px 10px;
      left: 0;
      right: 0;
      background: -moz-linear-gradient(left, rgba(38,129,192,1) 0%, rgba(125,185,232,0) 100%);
      background: -webkit-linear-gradient(left, rgba(38,129,192,1) 0%,rgba(125,185,232,0) 100%);
      background: linear-gradient(to right, rgba(38,129,192,1) 0%,rgba(125,185,232,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2681c0', endColorstr='#007db9e8',GradientType=1 );
      @include transition(); }
    &:hover {
      @include transition();
      figcaption {
        @include transition();
        bottom: 0;
        background: -moz-linear-gradient(left, rgba(38,129,192,1) 0%, rgba(125,185,232,0) 100%);
        background: -webkit-linear-gradient(left, rgba(38,129,192,1) 0%,rgba(125,185,232,0) 100%);
        background: linear-gradient(to right, rgba(38,129,192,1) 0%,rgba(125,185,232,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2681c0', endColorstr='#007db9e8',GradientType=1 ); }
      span {
        @include transition();
        background: rgba($color_yellow, 0.9); } }
    strong, {
        h2,
        .link {
          color: $color_white; } }

    strong, h2 {
      font-size: 16px;
      text-align: center;
      font-family: 'regular';
      color: $color_highlight;
      display: block; }
    .link {
      font-size: 14px;
      text-align: center;
      display: block;
      margin-top: 30px;
      text-decoration: none;
      background-color: rgba(255, 255, 255, 0.6);
      height: 50px;
      border-radius: 25px;
      line-height: 50px;
      width: 170px;
      margin: -40px auto 39px auto;
      color: #000; }
    .preview-btn {
      z-index: 20;
      position: absolute;
      width: 40px;
      height: 40px;
      top: 0;
      right: 0;
      @include transition();
      border-radius: 0 0 0 20px;
      background: rgba($color_white,.2);
      border: solid 1px rgba($color_white, .2);
      border-right: 0;
      border-top: 0;
      &:before {
        display: block;
        width: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 30px;
        color: $color_white;
        font-family: icon;
        content: url("../assets/custom/icon_cat_info.png");
        z-index: 10; }
      &:hover {
        text-decoration: none;
        border-color: rgba($color_white, .5);
        @include transition();
        background: rgba($color_white,.5);
        &:before {
          border: none; } } }

    a:not(.fresco) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      &:hover {
        text-decoration: none; } } }


  &.full {
    .content {
      width: 100%; }
    .card {
      width: 23.5%;
      margin-left: 2%;
      &:nth-child(4n + 1) {
        margin-left: 0; } } } }

.grid:after {
  content: '';
  display: block;
  clear: both; }
.grid-item {
  width: 25%;
  float: left;
  border: 0px solid #333;
  box-sizing: border-box;
  background-size: cover;
  position: relative;
  transition: all .5s ease;
  overflow: hidden;
  .img {
    height: 100%;
    width: 100%;
    transition: all .5s ease;
    background-size: cover;
    h2 {
      color: $color_white;
      font-family: 'bold';
      letter-spacing: -0.1em;
      font-size: 2vw;
      text-transform: uppercase;
      padding-top: 10%;
      margin-left: 6%; } }
  &:hover .img {
    cursor: pointer;
    transform: scale(1.05); }
  .btn.btn_tradegroup:hover .img {
    transform: scale(1.05); } }
.grid-item--width2 {
  width: 50%;
  .img {
    h2 {
      color: #000;
      font-family: 'regular';
      text-transform: none;
      padding-top: 77%;
      margin-left: 52%; } } }
.grid-item--width3 {
  width: 75%; }
