.pagination {
  height: 40px;
  a,
  .current {
    color: $color_white;
    margin-right: 3px;
    border-radius: 3px;
    padding: 5px 0;
    text-align: center;
    width: 27px;
    height: 30px;
    box-sizing: border-box;
    background: $color_highlight;
    display: block;
    float: left;
    @include transition();
    &:hover {
      text-decoration: none;
      background: darken($color_highlight, 10%);
      @include transition(); } }

  .current {
    font-size: 14px;
    background: $color_font;
    @include transition();
    &:hover {
      background: darken($color_font, 10%);
      @include transition(); } }

  .previous,
  .next {
    position: relative;
    padding: 0;
    i {
      display: block;
      width: 100%;
      height: 100%; }
    &:before {
      left: 2px;
      top: 4px;
      position: absolute;
      font-size: 24px;
      line-height: 22px;
      text-align: center;
      color: $color_white; } } }
