//########################################################################################################################Mixins
@mixin transition( $attr: all, $time: 0.25, $easing: ease-out ) {
  -webkit-transition: $attr $time+s $easing;
  -moz-transition: $attr $time+s $easing;
  transition: $attr $time+s $easing; }

@mixin position($top: auto, $right: auto, $bottom: auto, $left: auto) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left; }
