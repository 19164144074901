#crop {
  .crop_image {
    float: left;
    img {
      border: solid 1px $color_gray; } }
  table {
    tr.description td {
      padding-top: 20px; } }

  button[name="cmd_calc"] {
    right: 23px; }

  input[type="radio"] {
    float: left;
    width: 20px;
    margin-right: 10px; }

  #total {
    color: $color_success;
    font-family: bold; }

  label {
    font-size: 14px;
    width: 160px; } }
