#upload {
  .upload_option,
  #upload_info {
    padding: 20px 25px;
    background: $color_white;
    margin-bottom: 20px;
    position: relative;
    box-sizing: border-box;
    h1,h2,h3 {
      font-size: 18px;
      margin-bottom: 0;
      color: $color_font; } }

  #upload_section {
    background: transparent;
    float: left;
    width: 55%;
    margin: 25px 0 25px 25px;
    background-color: #fff;
    .btn.success {
      height: 40px;
      line-height: 40px !important;
      margin-left: 10px;
      color: $color_blue; }
    .btn {
      border-radius: 25px;
      float: right;
      margin-left: 0;
      &.upload {
        &:hover {
          border-color: $color_yellow;
          color: $color_yellow; } }
      &.delete {
        background-color: #d12b0b;
        color: $color_white;
        &:hover {
          border-color: #d12b0b;
          color: #d12b0b; } }
      &:hover {
        border-color: #97afd2;
        background: none;
        color: #97afd2; } }
    .template-download,
    .template-upload {
      clear: both;
      height: 100px;
      > div {
        float: left; }
      .file_info {
        margin-left: 10px; }
      .buttons {
        float: right;
        a.btn {
          float: left;
          margin-right: 5px; } }
      .error {
        width: 700px;
        margin-left: 10px;
        color: $color_error; } }

    .btn.continue {
      float: right;
      width: 200px;
      margin-bottom: 30px;
      color: $color_white;
      &:hover {
        color: $color_blue; } } }

  #upload_info {
    float: left;
    width: 40%;
    margin-top: 20px;
    padding: 0;
    h3 {
      background-color: #f7f7f7;
      padding: 20px 20px 10px 80px;
      color: $color_blue;
      font-weight: 600; }

    .rte_container {
      margin: 20px 210px 20px 80px;
      font-size: 12px;
      position: relative;
      .headline {
        color: $color_blue;
        font-size: 16px;
        margin: 0 !important; } }
    .img {
      width: 248px;
      height: 322px;
      position: absolute;
      right: -25px;
      bottom: 0; } }
  .upload_field {
    .btn {
      float: right;
      width: 48%;
      height: 45px;
      line-height: 45px;
      border-radius: 25px;
      margin-left: 0;
      background: $color_yellow;
      color: $color_white;
      border-color: darken($color_yellow, 5%);
      &:before {
        content: none;
        color: $color_white; } } }

  .upload_option {
    position: relative;
    padding: 15px 20px 15px 60px;
    h3 {
      padding: 0;
      margin: 0;
      width: 100%;
      text-align: left;
      color: $color_blue;
      font-family: 'regular';
      font-size: 16px;
      font-weight: 300;
      &:after {
        display: none; }
      strong {
        color: $color_blue;
        font-family: 'regular';
        font-size: 16px;
        font-weight: 600; } }

    &:before {
      font-family: 'icon';
      color: $color_font;
      font-size: 32px;
      top: 15px;
      left: 10px;
      content: ''; }
    &#upload_browser input {
      padding-left: 65px; }
    &#upload_browser input:before {
      content: url("../assets/custom/icon_upload_browser.png");
      display: block;
      width: 60px;
      position: absolute;
      left: 0; }
    &#upload_ftp:before {
      content: ''; }
    &#upload_cd:before {
      content: 'e'; }
    &#upload_email p:before {
      content: url("../assets/custom/icon_upload_mail.png");
      display: block;
      width: 100px;
      float: left; }
    &#upload_fotolia:before {
      content: 'm'; }

    .btn.default {
      float: right;
      width: 48%;
      box-sizing: border-box;
      margin: 0; }

    p {
      float: left;
      width: 48%; } } }
