.hint {

  background: lighten(desaturate($color_error, 50%),50%);
  color: $color_error;
  padding: 15px $grid;
  font-size: 18px;
  box-sizing: border-box;
  margin-bottom: 25px;
  border: solid 1px $color_error;
  &.wrp,
  .wrp {
    width: 100%; }
  strong {
    display: inline-block;
    font-size: 18px;
    font-family: 'bold';
    color: $color_error !important; }

  li {
    color: $color_error; }
  ul {
    margin: 0; }
  li:before {
    background: $color_error;
    color: $color_error; }

  &.message {
    background: $color_gray;
    padding: 10px;
    color: $color_font;
    display: block;
    box-sizing: border-box;
    border: solid 1px darken($color_gray, 10%);
    font-size: 16px;
    &.success {
      border-color: $color_success;
      background: lighten(desaturate($color_success, 50%),50%);
      color: $color_success;
      * {
        color: $color_success; }
      li {
        color: $color_success;
        &:before {
          display: none; } }
      strong {
        color: $color_success; } }

    * {
      color: $color_font; }
    li {
      color: $color_font;
      &:before {
        display: none; } }
    strong {
      color: $color_font !important; }

    .btn.success#kundenholensubmit {
      color: $color_white;
      margin-left: 20px; } } }

.error_msg {
  color: $color_error;
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
  strong {
    display: block;
    font-size: 18px;
    margin-bottom: 10px;
    font-family: 'bold';
    color: $color_error !important; } }
