#impressum {
  address {
    background: $color_white;
    float: right;
    width: 400px;
    box-sizing: border-box;
    padding: 30px 0; }
  article {
    background: #fff;
    width: 700px;
    float: left;
    box-sizing: border-box;
    padding: 30px 0;
    margin-bottom: $grid;
    &.fullwidth {
      float: none;
      width: 100%; } } }
