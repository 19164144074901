header.main {
  height: 144px;
  .wrp {
    box-sizing: border-box;
    padding: 0 10px; }
  #service-bar {
    background: #4b4b4b;
    height: 30px;
    position: fixed;
    width: 100vw;
    bottom: 0;
    z-index: 5;
    a {
      font-size: 12px;
      color: $color_white; }
    span {
      font-size: 12px;
      line-height: 30px;
      float: left;
      margin-right: 3px;
      color: $color_white;
      &.label_tel,
      &.label_mail {
        font-family: 'bold';
        color: #4bbad6;
        &:before {
          float: left;
          font-family: 'icon';
          color: $color_white;
          font-size: 21px;
          content: '';
          margin-right: 5px; } }
      &.label_mail:before {
        content: '|';
        margin-top: -4px;
        margin-left: 3px; } }

    ul {
      float: right;
      margin: 0;
      li {
        margin-left: 5px;
        display: inline-block;
        float: left;
        line-height: 30px;
        &:before {
          display: none; }
        a {
          line-height: 30px;
          color: $color_white;
          font-size: 12px; } } } }


  #brand-logo {
    margin-top: 23px;
    clear: both;
    width: 200px;
    margin-right: 20px;
    display: block;
    float: left;
    img {
      min-width: 80px;
      max-width: 320px;
      height: auto; } }

  #customer-menu {
    position: relative;
    float: right;
    height: 65px;
    margin-top: 25px;
    a,
    > button {
      background: $color_blue;
      display: block;
      @include transition();
      width: 90px;
      height: 50px;
      float: left;
      position: relative;
      margin-left: 16px;
      border-radius: 45px;
      &.menu-item-login {
        background: $color_white;
        -webkit-box-shadow: 0px 3px 15px 0px rgba(50, 50, 50, 0.25);
        -moz-box-shadow: 0px 3px 15px 0px rgba(50, 50, 50, 0.25);
        box-shadow: 0px 3px 15px 0px rgba(50, 50, 50, 0.25);
        width: 160px;
        font-weight: 500;
        font-size: 14px;
        color: $color_blue;
        padding-left: 25px;
        &:hover {
          -webkit-box-shadow: 0px 3px 15px 0px rgba(50, 50, 50, 0.5);
          -moz-box-shadow: 0px 3px 15px 0px rgba(50, 50, 50, 0.5);
          box-shadow: 0px 3px 15px 0px rgba(50, 50, 50, 0.5); } }
      &.menu-item-login:before,
      &.menu-item-cart:before,
      &.menu-item-search:before {
        display: block;
        width: 90px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-family: 'icon';
        color: $color_white;
        font-size: 30px; }
      &.menu-item-login:before {
        content: url(../assets/custom/icon_login.png);
        height: 20px;
        width: 20px;
        line-height: 10px;
        position: absolute;
        left: 25px; }
      &.menu-item-cart:before {
        content: url(../assets/custom/icon_cart.png);
        line-height: 43px; }



      &:hover {
        @include transition();
        text-decoration: none;
        /*background: darken($color_gray, 10%) */ }
      &.active {
        background: $color_white;
        &:before {
          color: $color_white; }
        .count {
          color: $color_success;
          background: $color_white; } }

      .count {
        position: absolute;
        top: 12px;
        right: -7px;
        background: #f6bd30;
        color: $color_white;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        display: block; } }
    #search.modal {
      top: 65px;
      right: 0px !important;
      width: 320px;
      font-size: 12px; }
    #login.modal,
    #search.modal {
      top: 65px;
      right: 81px;
      width: 320px;
      font-size: 12px;
      a,
      button {
        float: none;
        text-align: left;
        background: $color_gray;
        display: block;
        height: 30px;
        font-size: 16px;
        width: 100%;
        box-sizing: border-box;
        padding: 3px 10px;
        border-radius: 2px;
        color: $color_font;
        margin: 5px 0 0 0 !important;
        @include transition();
        &:hover {
          text-decoration: none;
          background: #e4edf4;
          color: $color_blue;
          @include transition(); } }
      .btn {
        padding: 10px;
        width: 100%;
        margin: 5px 0 0 0;
        background: #e4edf4;
        color: $color_blue;
        height: 40px;
        line-height: 20px;
        &:hover {
          background-color: $color_blue;
          color: $color_white; } }

      fieldset {
        background: $color_gray;
        //padding-top: 10px
        input {
          border: solid 1px lighten($color_font, 40%);
          width: 180px;
          background: $color_white;
          height: 30px;
          float: right;
          margin-right: 10px;
          margin-top: 5px;
          padding: 5px 10px;
          box-sizing: border-box;
          color: $color_font;
          font-size: 12px; }
        label {
          width: 110px;
          margin-right: 5px;
          display: inline-block;
          font-family: 'bold';
          font-size: 14px; } } } } }

header.headline {
  margin-bottom: -48px;
  &.article {
    margin-bottom: -30px; }
  img {
    width: 100%;
    height: auto; } }
