#orders,
#orders_canceled {
  margin-bottom: $grid;
  a {
    padding: 5px;
    border: solid 1px darken($color_white, 5%);
    background: $color_white;
    margin-bottom: 20px;
    display: block;
    border-radius: 4px;
    &:hover {
      text-decoration: none; }
    strong {
      line-height: 40px;
      margin: 0 10px;
      float: left;
      display: block; }
    em {
      line-height: 40px; }
    .btn {
      margin-bottom: 0;
      float: right; }
    &:hover .btn {
      border-color: $color_blue;
      background: $color_white;
      color: $color_blue; } } }

#account_tracking {
  .col_left,
  .col_right {
    section {
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0; } } } }
