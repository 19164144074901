.cat_listing {
  padding-top: $grid;
  //###########################################recursive_cat_listing
  .listing {
    margin-bottom: $grid;
    width: 880px;
    float: left; }
  article {
    header {
      background: $color_gray;
      //padding: 20px 40px
      //height: 30px
      padding: 0 40px;
      color: $color_font;
      font-size: 18px;
      margin-bottom: 3px;
      line-height: 30px;
      h3 {
        margin: 0;
        float: left;
        padding: 20px 0;
        font-weight: bold;
        display: inline-block;
        line-height: 30px;
        max-width: 300px;
        overflow: hidden;
        white-space: nowrap;
        color: $color_font;
        font-size: 18px; }
      table {
        height: 30px;
        border-collapse: separate;
        float: right;
        width: 440px;
        td {
          color: $color_font;
          height: 30px;
          padding: 20px 10px;
          font-size: 18px;
          border-right: solid 6px $color_white;
          &:first-child {
            width: 180px; }
          &:nth-child(2),
          &:nth-child(3) {
            width: 84px; } } } }
    .content {
      figure {
        float: left;
        width: 400px;
        margin-bottom: $grid;
        img {
          margin: 3px 0 $grid 0;
          max-width: 270px;
          height: auto; } }

      table {
        cursor: pointer;
        float: left;
        width: 480px;
        border-collapse: separate;
        td {
          height: 20px;
          border: solid 3px $color_white;
          font-size: 14px;
          background: $color_gray;
          color: $color_font;
          padding: 10px;
          width: 90px;
          @include transition();
          &:first-child {
            width: 190px; }
          &:last-child {
            border-right: none;
            width: 20px;
            background: lighten(desaturate($color_success, 30%),30%);
            &:before {
              display: block;
              height: 20px;
              width: 20px;
              text-indent: -7px;
              line-height: 22px;
              color: $color_white;
              font-family: 'icon';
              content: 'r';
              font-size: 35px; } } }
        tr {
          position: relative;
          display: block; }
        tr:nth-child(odd) td {
            background: darken($color_gray, 10%);
            &:last-child {
              background: lighten(desaturate($color_success, 30%),30%); } }

        tr:hover td {
          color: $color_white;
          background: lighten(desaturate($color_success,35%),10%);
          @include transition();
          &:last-child {
            color: $color_white;
            background: lighten(desaturate($color_success,35%),10%); } }
        a {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          &:hover {
            text-decoration: none; } } } } } }

.description {
  padding: $grid 0 0 0; }

.cat_text {
  position: absolute;
  left: 11%;
  top: 25%;
  color: #f6bd30;
  font-size: 2.4vw;
  font-weight: 200;
  letter-spacing: -0.1em;
  h1 {
    color: #fff;
    font-family: 'bold';
    font-size: 5vw;
    letter-spacing: -0.1em;
    margin-top: -4%; } }
