.picker {
  font-size: 16px;
  text-align: left;
  line-height: 1.2;
  color: #000000;
  position: absolute;
  z-index: 10000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.picker__input {
  cursor: default; }

.picker__input.picker__input--active {
  border-color: #0089ec; }

.picker__holder {
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.picker {
  width: 100%; }

.picker__holder {
  position: absolute;
  background: #ffffff;
  border: 1px solid #aaaaaa;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0 0 5px 5px;
  box-sizing: border-box;
  min-width: 176px;
  max-width: 466px;
  max-height: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  -webkit-transform: translateY(-1em) perspective(600px) rotateX(10deg);
  transform: translateY(-1em) perspective(600px) rotateX(10deg);
  transition: -webkit-transform 0.15s ease-out, opacity 0.15s ease-out, max-height 0s 0.15s, border-width 0s 0.15s;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out, max-height 0s 0.15s, border-width 0s 0.15s; }

.picker__frame {
  padding: 1px; }

.picker__wrap {
  margin: -1px; }

.picker--opened .picker__holder {
  max-height: 25em;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
  border-top-width: 1px;
  border-bottom-width: 1px;
  -webkit-transform: translateY(0) perspective(600px) rotateX(0deg);
  transform: translateY(0) perspective(600px) rotateX(0deg);
  transition: -webkit-transform 0.15s ease-out, opacity 0.15s ease-out, max-height 0s, border-width 0s;
  transition: transform 0.15s ease-out, opacity 0.15s ease-out, max-height 0s, border-width 0s;
  box-shadow: 0 6px 18px 1px rgba(0, 0, 0, 0.12); }

.picker__box {
  padding: 0 1em; }

.picker__header {
  text-align: center;
  position: relative;
  margin-top: .75em; }

.picker__month,
.picker__year {
  font-weight: 500;
  display: inline-block;
  margin-left: .25em;
  margin-right: .25em; }

.picker__year {
  color: #999999;
  font-size: .8em;
  font-style: italic; }

.picker__select--month,
.picker__select--year {
  border: 1px solid #b7b7b7;
  height: 2em;
  padding: .5em;
  margin-left: .25em;
  margin-right: .25em; }

@media (min-width: 24.5em) {
  .picker__select--month,
  .picker__select--year {
    margin-top: -0.5em; }


  .picker__select--month {
    width: 35%; }

  .picker__select--year {
    width: 22.5%; }

  .picker__select--month:focus,
  .picker__select--year:focus {
    border-color: #0089ec; }

  .picker__nav--prev,
  .picker__nav--next {
    position: absolute;
    padding: .5em 1.25em;
    width: 1em;
    height: 1em;
    box-sizing: content-box;
    top: -0.25em; } }

@media (min-width: 24.5em) {
  .picker__nav--prev,
  .picker__nav--next {
    top: -0.33em; }


  .picker__nav--prev {
    left: -1em;
    padding-right: 1.25em; } }

@media (min-width: 24.5em) {
  .picker__nav--prev {
    padding-right: 1.5em; }


  .picker__nav--next {
    right: -1em;
    padding-left: 1.25em; } }

@media (min-width: 24.5em) {
  .picker__nav--next {
    padding-left: 1.5em; }


  .picker__nav--prev:before,
  .picker__nav--next:before {
    content: " ";
    border-top: .5em solid transparent;
    border-bottom: .5em solid transparent;
    border-right: 0.75em solid #000000;
    width: 0;
    height: 0;
    display: block;
    margin: 0 auto; }

  .picker__nav--next:before {
    border-right: 0;
    border-left: 0.75em solid #000000; }

  .picker__nav--prev:hover,
  .picker__nav--next:hover {
    cursor: pointer;
    color: #000000;
    background: #b1dcfb; }

  .picker__nav--disabled,
  .picker__nav--disabled:hover,
  .picker__nav--disabled:before,
  .picker__nav--disabled:before:hover {
    cursor: default;
    background: none;
    border-right-color: #f5f5f5;
    border-left-color: #f5f5f5; }

  .picker__table {
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    font-size: inherit;
    width: 100%;
    margin-top: .75em;
    margin-bottom: .5em; } }

@media (min-height: 33.875em) {
  .picker__table {
    margin-bottom: .75em; }

  .picker__table td {
    margin: 0;
    padding: 0; }

  .picker__weekday {
    width: 14.285714286%;
    font-size: .75em;
    padding-bottom: .25em;
    color: #999999;
    font-weight: 500; } }

@media (min-height: 33.875em) {
  .picker__weekday {
    padding-bottom: .5em; }

  .picker__day {
    padding: .3125em 0;
    font-weight: 200;
    border: 1px solid transparent; }

  .picker__day--today {
    position: relative; }

  .picker__day--today:before {
    content: " ";
    position: absolute;
    top: 2px;
    right: 2px;
    width: 0;
    height: 0;
    border-top: 0.5em solid #0059bc;
    border-left: .5em solid transparent; }

  .picker__day--disabled:before {
    border-top-color: #aaaaaa; }

  .picker__day--outfocus {
    color: #dddddd; }

  .picker__day--infocus:hover,
  .picker__day--outfocus:hover {
    cursor: pointer;
    color: #000000;
    background: #b1dcfb; }

  .picker__day--highlighted {
    border-color: #0089ec; }

  .picker__day--highlighted:hover,
  .picker--focused .picker__day--highlighted {
    cursor: pointer;
    color: #000000;
    background: #b1dcfb; }

  .picker__day--selected,
  .picker__day--selected:hover,
  .picker--focused .picker__day--selected {
    background: #0089ec;
    color: #ffffff; }

  .picker__day--disabled,
  .picker__day--disabled:hover,
  .picker--focused .picker__day--disabled {
    background: #f5f5f5;
    border-color: #f5f5f5;
    color: #dddddd;
    cursor: default; }

  .picker__day--highlighted.picker__day--disabled,
  .picker__day--highlighted.picker__day--disabled:hover {
    background: #bbbbbb; }

  .picker__footer {
    text-align: center; }

  .picker__button--today,
  .picker__button--clear,
  .picker__button--close {
    border: 1px solid #ffffff;
    background: #ffffff;
    font-size: .8em;
    padding: .66em 0;
    font-weight: bold;
    width: 33%;
    display: inline-block;
    vertical-align: bottom; }

  .picker__button--today:hover,
  .picker__button--clear:hover,
  .picker__button--close:hover {
    cursor: pointer;
    color: #000000;
    background: #b1dcfb;
    border-bottom-color: #b1dcfb; }

  .picker__button--today:focus,
  .picker__button--clear:focus,
  .picker__button--close:focus {
    background: #b1dcfb;
    border-color: #0089ec;
    outline: none; }

  .picker__button--today:before,
  .picker__button--clear:before,
  .picker__button--close:before {
    position: relative;
    display: inline-block;
    height: 0; }

  .picker__button--today:before,
  .picker__button--clear:before {
    content: " ";
    margin-right: .45em; }

  .picker__button--today:before {
    top: -0.05em;
    width: 0;
    border-top: 0.66em solid #0059bc;
    border-left: .66em solid transparent; }

  .picker__button--clear:before {
    top: -0.25em;
    width: .66em;
    border-top: 3px solid #ee2200; }

  .picker__button--close:before {
    content: "\D7";
    top: -0.1em;
    vertical-align: top;
    font-size: 1.1em;
    margin-right: .35em;
    color: #777777; }

  .picker__button--today[disabled],
  .picker__button--today[disabled]:hover {
    background: #f5f5f5;
    border-color: #f5f5f5;
    color: #dddddd;
    cursor: default; }

  .picker__button--today[disabled]:before {
    border-top-color: #aaaaaa; } }
