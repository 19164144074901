.kdz-switch-item {
  display: none; }
.kdz-switch-item.active {
  display: block; }
.kdz-switch {
  cursor: pointer; }
#product-detail form h2 {
  background: $color_blue;
  width:  100%;
  text-align:  left;
  padding:  10px;
  color: white;

  &:before {
    content: '';
    position: absolute;
    bottom: -12px;
    display: block;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid $color_blue; } }
#product-detail form h2::after {
  content: none;
  display: none; }
#configurator select, #configurator input, #configurator textarea {
  border-radius: 5px !important; }

#detail_infobar_gallery {
  .gallery_preview {
    margin-top: 10px; }
  ul {
    margin-top: 10px; } }

#product-detail {
  form {
    .info:before {
      background-color: $color_yellow !important; } } }

@media screen and (max-width: 600px) {
  #product-detail form .info {
    display: block !important;
    top: 11px !important;
    left: 1px !important; } }
