#slider {
  width: auto;
  position: relative;
  z-index: 0;
  .slide {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    img {
      position: relative;
      z-index: 0;
      width: 100%;
      height: auto; }
    figcaption {
      width: 100%;
      z-index: 2;
      position: absolute;
      left: 8%;
      bottom: 150px;
      strong {
        padding: 0 10px;
        font-family: 'regular';
        display: inline-block;
        margin-bottom: 3%;
        font-size: 3.4vw;
        letter-spacing: -0.07em;
        color: $color_white; }
      p {
        display: block;
        color: $color_white;
        font-family: 'bold';
        font-size: 8vw;
        text-transform: uppercase;
        letter-spacing: -0.07em;
        margin-bottom: 8%; }
      em {
        font-size: 18px;
        color: $color_white; } }
    &.active figcaption {
      -webkit-animation: sliderCaption 0.35s 0s 1 ease-in;
      -moz-animation: sliderCaption 0.35s 0s 1 ease-in;
      -ms-animation: sliderCaption 0.35s 0s 1 ease-in;
      animation: sliderCaption 0.35s 0s 1 ease-in; } }
  .wrp {
    height: 100%; }
  .side-nav {
    position: absolute;
    z-index: 2;
    display: block;
    width: 40px;
    height: 40px;
    top: 35%;
    margin-top: -20px;
    cursor: pointer;
    &:before {
      content: '';
      font-family: 'icon';
      width: 60px;
      height: 60px;
      display: block;
      line-height: 60px;
      text-align: center;
      color: $color_white;
      border-radius: 40px;
      border: 1px solid rgba($color_highlight,0.5);
      @include transition(); }
    &:hover:before {
      @include transition();
      background: rgba($color_highlight,0.5);
      color: $color_white; }
    &.slide-left {
      left: -2vw;
      &:before {
        content: url(../assets/custom/icon_slider_left.png); } }
    &.slide-right {
      right: -1vw;

      &:before {
        content: url(../assets/custom/icon_slider_right.png); } } }
  #controlNav {
    margin: 0;
    position: absolute;
    z-index: 1;
    bottom: 22%;
    right: 15%;
    text-align: right;
    width: 100%;
    display: block;
    a {
      width: 8px;
      height: 8px;
      border-radius: 6px;
      display: inline-block;
      margin: 0 5px;
      background: $color_gray;
      border: solid 1px darken($color_gray, 20%);
      &.current {
        background: lighten($color_highlight, 40%);
        border: solid 1px $color_highlight; } } } }



//############################################################Animations

@-webkit-keyframes sliderCaption {
  0% {
    left: -40px; }
  100% {
    left: 0; } }
@-moz-keyframes sliderCaption {
  0% {
    left: -40px; }
  100% {
    left: 0; } }
@-ms-keyframes sliderCaption {
  0% {
    left: -40px; }
  100% {
    left: 0; } }
@keyframes sliderCaption {
  0% {
    left: -40px; }
  100% {
    left: 0; } }
