.climate {
  margin: 20px 0 40px 0;
  .climateItem {
    background: $color_white;
    margin-bottom: 20px;
    border-bottom: 1px solid #e4edf4;
    .accContent {
      display: none;
      margin-left: 195px;
      li {
        margin-left: 20px;
        list-style-type: disc; }
      img {
        width: 100%;
        height: auto; } }
    .accHead {
      display: block;
      cursor: pointer;
      padding: 10px;
      min-height: 110px;
      box-sizing: border-box;
      margin-left: 50px;
      .openBtn {
        float: left;
        margin: 25px 10px 0 5px;
        background: #fff;
        position: relative;
        color: #ccc;
        display: block;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        &:before {
          content: url("../assets/custom/icon_btn_forward_hover.png");
          position: absolute;
          color: #ccc;
          display: block;
          height: 60px;
          width: 60px;
          font-size: 30px;
          line-height: 60px;
          text-align: center;
          top: -22px;
          left: 0;
          border: 1px solid #e5e5e5;
          border-radius: 50%;
          color: $color_blue; } }
      h3 {
        color: $color_blue;
        padding: 20px;
        margin-bottom: 0;
        margin-left: 60px;
        float: left;
        font-size: 20px;
        font-weight: 600; }
      .climateInfobox {
        background: #fff;
        width: 350px;
        float: right;
        padding: 5px 10px;
        margin: 5px;
        border-radius: 5px;
        box-sizing: border-box;
        span {
          width: 180px;
          display: inline-block; } } }

    .accHead.current {
      h3 {
        font-weight: 300; }
      .openBtn {
        &:before {
          content: url("../assets/custom/icon_btn_forward_hover.png");
          transform: rotate(90deg); }
        @include transition(); } } } }

@media screen and (max-width: 800px) {
  .climate {
    .climateItem {
      .accHead {
        .climateInfobox {
          float: none;
          width: calc(100% - 10px); }
        h3 {
          float: none; } } } } }

.climatePartnerAccount {
  span {
    display: inline-block;
    width: 150px;
    font-weight: bold; } }

@media screen and (max-width: 480px) {
  .climatePartnerAccount {
    span {
      display: block;
      width: 100%; } } }
