#calculator_preloader {
  text-align: center;
  &:before {
    content: '';
    display: block;
    font-size: 10px;
    margin: 20px auto;
    border-radius: 100px;
    width: $font-size;
    height: $font-size;
    text-indent: -9999em;
    border: solid 3px $color_highlight;
    border-bottom-color: $color_white;
    -webkit-animation: loader 1.1s infinite linear;
    -moz-animation: loader 1.1s infinite linear;
    animation: loader 1.1s infinite linear; } }

#calculator_searchresults {
  label {
    width: 355px; } }
