#opc_image {
  border: solid 1px $color_gray;
  max-width: 100%;
  height: auto;
  margin-bottom: $grid; }

#configurator {
  min-height: 625px;
  position: relative;
  padding-top: 90px;
  box-shadow: 0 0 15px -4px rgba(0,0,0,0.15), 0 0 20px -4px rgba(0,0,0,0.15);
  margin: 25px;
  z-index: 5;
  width: 760px;
  h1 {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    right: 0;
    background: $color_blue_mdd;
    height: 50px;
    line-height: 50px;
    padding-left: 67px;
    font-size: 21px;
    font-weight: 600;
    color: white;
    margin-bottom: 0px !important;
    &:before {
      content: '';
      position: absolute;
      bottom: -12px;
      display: block;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 12px solid $color_blue_mdd; }
    small {
      font-size: 12px;
      display: inline-block;
      margin-left: 20px;
      color: white; } } }

#product-detail {
  background: $color_white;
  padding: $grid 0;
  .actionbar {
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding-right: 10px;
    .btn {
      margin-left: 0;
      width: 46%; }
    hr {
      display: none; } }
  .template-download,
  .template-upload {
    clear: both;
    height: 100px;
    > div {
      float: left; }
    .file_info {
      margin-left: 10px; }
    .buttons {
      float: right;
      a.btn {
        float: left;
        margin-right: 5px; }
      .btn.delete.cancel {
        width: auto; } }
    .error {
      width: 700px;
      margin-left: 10px;
      color: $color_error; } }

  .template-upload .btn.start {
    position: absolute;
    left: 10000px; }
  .template-download {
    display: none; }

  #detail_infobar {
    min-height: 20px;
    float: right;
    width: 320px;
    max-width: 320px !important;
    margin: 25px 20px 0 0;
    padding: 0 15px 0 0;
    h3 {
      box-sizing: border-box;
      background: $color_blue_mdd;
      height: 50px;
      line-height: 50px;
      font-size: 21px;
      font-weight: 600;
      color: $color_white;
      margin: 0 !important;
      display: block;
      width: 100%;
      padding: 0 0 0 20px;
      text-align: left;
      font-family: 'regular';
      &:before {
        content: '';
        position: absolute;
        bottom: -12px;
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 12px solid $color_blue_mdd; }
      &:after {
        display: none; } }
    #detail_infobar_text,
    #detail_infobar_gallery,
    #detail_infobar_sheets,
    #detail_bookinfo {
      margin-bottom: 20px;
      padding: 15px;
      background: $color_white; }
    li {
      padding: 0;

      &:before {
        display: none; } }
    input[type="checkbox"] {
      margin-top: 5px; }

    a.pdf,
    a.indd,
    a.ai {
      line-height: 38px;
      height: 40px;
      display: block;
      position: relative;
      padding-left: 35px;
      &:before {
        position: absolute;
        color: lighten($color_font, 15%);
        font-size: 36px;
        font-family: icon;
        content: 'P';
        left: 0;
        top: 3px; } }
    a.indd:before {
      content: 'I'; }
    a.ai:before {
      content: 'A'; }

    #detail_infobar_gallery {
      padding: 0;
      .gallery_preview {
        width: 100%;
        height: auto; }
      li {
        width: 33.33333333333333333333333333333333%;
        float: left;
        padding: 0;
        @include transition();
        img {
          width: 100%;
          height: auto;
          cursor: pointer; }

        &:hover {
          opacity: 0.5;
          @include transition(); } } } }
  #detail_infobar-opc {
    min-height: 20px;
    width: 100%;
    margin-right: 20px;
    #detail_infobar_gallery {
      padding: 0;
      .gallery_preview {
        width: 870px;
        height: auto; }
      li {
        height: 60px;
        padding-right: 10px;
        float: left;
        &:before {
          background: none;
          margin-top: 3px; }
        @include transition();
        img {
          width: 100%;
          height: auto;
          cursor: pointer; }

        &:hover {
          opacity: 0.5;
          @include transition(); } } } }

  #total_block,
  #delivery {
    background: $color_white;
    box-shadow: 0 0 15px -4px rgba(0,0,0,0.15), 0 0 20px -4px rgba(0,0,0,0.15);
    float: left;
    width: 100%;
    padding: 80px 15px 15px 15px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: $grid / 2;
    box-sizing: border-box;
    .upload_field {
      input, label {
        width: 100%;
        margin: 0;
        box-sizing: border-box;
        display: block;
        float: none; }
      label {
        text-align: left; } } }
  #printoffer {
    color: $color_blue;
    background: #e4edf4;
    float: left;
    width: 100%;
    padding: 10px 15px 10px 85px;
    box-sizing: border-box;
    position: relative;
    box-sizing: border-box;
    &:before {
      content: url("../assets/custom/icon_printoffer.png");
      position: absolute;
      left: 40px; }

    a {
      color: $color_blue; }
    br {
      display: none; } }
  .sidescroller {
    left: 785px !important;
    margin-top: 25px;
    max-width: 345px !important; }
  #total_block {
    li {
      font-size: 13px; }
    input,
    input:not(.btn),
    .non-input-field,
    select {
      width: 100%; }

    h1,h2,h3 {
      width: auto;
      text-align: left;
      margin: 0 0 15px 0;
      top: 0;
      left: 0;
      right: 0;
      font-family: 'regular';
      font-size: 18px;
      position: absolute;
      background: $color_highlight;
      background: -moz-linear-gradient(top,  $color_highlight 0%, darken($color_highlight, 5%) 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$color_highlight), color-stop(100%,darken($color_highlight, 5%)));
      background: -webkit-linear-gradient(top,  $color_highlight 0%,darken($color_highlight, 5%) 100%);
      background: -o-linear-gradient(top,  $color_highlight 0%,darken($color_highlight, 5%) 100%);
      background: -ms-linear-gradient(top,  $color_highlight 0%,darken($color_highlight, 5%) 100%);
      background: linear-gradient(to bottom,  $color_highlight 0%,darken($color_highlight, 5%) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#17afc1', endColorstr='#149bad',GradientType=0 );
      height: 70px;
      line-height: 40px;
      padding: 15px;
      color: $color_white;
      &:after {
        display: none; } }
    h3 {
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
      right: 0;
      background: $color_blue_mdd;
      height: 50px;
      line-height: 50px;
      padding: 0 0 0 25px;
      font-size: 21px;
      font-weight: 600;
      color: $color_white;
      margin: 0;
      &:before {
        content: '';
        position: absolute;
        bottom: -12px;
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 12px solid $color_blue_mdd; } }
    hr {
      margin: 15px 0; }
    li {
      color: #333;
      display: block;
      text-align: right;
      &:before {
        display: none; }
      &.discount * {
        color: darken($color_success, 20%); }
      &.total {
        width: 100%;
        height: 80px;
        position: relative;
        background: $color_blue_mdd;
        margin-left: 10px;
        padding: 15px 10px 0 10px;
        box-sizing: border-box;

        span:before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-top: 40px solid transparent;
          border-right: 20px solid $color_blue_mdd;
          border-bottom: 40px solid transparent;
          margin: -15px 0 0 -30px; } }

      &.total.gross {
        color: $color_yellow;
        font-size: 25px;
        .vat {
          font-size: 22px;
          color: $color_yellow;
          margin-top: 0;
          font-weight: 500; }
        small {
          color: $color_white;
          font-size: 11px;
          line-height: 14px;
          br {
            font-size: 11px;
            line-height: 14px; } } }

      strong {
        text-align: left;
        display: block;
        border-bottom: solid 1px $color_gray;
        margin-bottom: 5px;
        padding-bottom: 5px; }
      .label {
        display: inline-block;
        width: 50%;
        float: left;
        text-align: left;
        small {
          display: inline-block;
          color: $color_font;
          font-size: 12px; } } }

    .btn {
      display: block;
      width: 100%;
      font-size: 18px;
      line-height: 12px;
      text-align: center; } }


  #delivery {
    padding-top: 20px;
    strong {
      margin-bottom: 10px;
      display: block; }
    .icon:before {
      font-size: 22px;
      margin: 5px 10px  0 0;
      color: $color_highlight;
      float: left; }
    span, time {
      font-size: 14px; }

    &:hover {
      .tooltip {
        @include transition();
        opacity: 1;
        visibility: visible; } } }

  #bulk {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    display: block;
    background: lighten($color_gray, 1%);
    background: #ffffff;
    background: -moz-linear-gradient(top,  #ffffff 0%, #f9f9f9 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffffff), color-stop(100%,#f9f9f9));
    background: -webkit-linear-gradient(top,  #ffffff 0%,#f9f9f9 100%);
    background: -o-linear-gradient(top,  #ffffff 0%,#f9f9f9 100%);
    background: -ms-linear-gradient(top,  #ffffff 0%,#f9f9f9 100%);
    background: linear-gradient(to bottom,  #ffffff 0%,#f9f9f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f9f9f9',GradientType=0 );
    border-bottom: solid 1px darken($color_gray, 5%);
    height: 40px;
    padding: 0 15px;
    line-height: 30px;
    .info {
      display: inline-block;
      float: none;
      .tooltip {
        left: auto;
        right: 40px;
        width: 580px;
        max-width: 620px;
        display: block;
        > strong:first-child {
          display: block;
          margin-bottom: 10px;
          font-family: 'bold';
          font-size: 18px; } }

      .col {
        float: left;
        width: 33.333333%;
        display: block; }
      strong {
        font-size: 14px;
        display: block; }
      li {
        text-align: left;
        font-size: 12px; } } }
  #bulk_height {
    height: 40px; } }

#product_informations {
  margin-top: $grid;
  .tab {
    position: relative;
    z-index: 1;
    cursor: pointer;
    box-sizing: border-box;
    float: left;
    display: block;
    padding: 10px $grid / 2;
    font-size: 16px;
    border: solid 1px darken($color_gray, 10%);
    background: darken($color_gray, 5%);
    color: $color_font;
    margin-bottom: -1px;
    border-bottom: none;
    &.current {
      background: $color_white; } }
  .content {
    position: relative;
    z-index: 0;
    background: $color_white;
    padding: $grid $grid / 2;
    border: solid 1px darken($color_gray, 10%); }
  .tab_content {
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    h1,h2,h3,h4,h5,h6 {
      small {
        display: block;
        font-size: 12px; } } } }
