@font-face {
  font-family: 'icon';
  src: url($fontpath + 'druckshop-premium.eot');
  src: url($fontpath + 'druckshop-premium.eot?#iefix') format('embedded-opentype'), url($fontpath + 'druckshop-premium.woff') format('woff'), url($fontpath + 'druckshop-premium.ttf') format('truetype'), url($fontpath + 'druckshop-premium.svg#druckshop-premium') format('svg');
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'regular';
  src: url($fontpath + 'poppins-regular-webfont.eot');
  src: url($fontpath + 'poppins-regular-webfont?#iefix') format('embedded-opentype'), url($fontpath + 'poppins-regular-webfont.woff') format('woff2'), url($fontpath + 'poppins-regular-webfont.woff') format('woff'), url($fontpath + 'poppins-regular-webfont.ttf') format('truetype'), url($fontpath + 'poppins-regular-webfont.svg#poppinsregular') format('svg');
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'bold';
  src: url($fontpath + 'poppins-bold-webfont.eot');
  src: url($fontpath + 'poppins-bold-webfont.eot?#iefix') format('embedded-opentype'), url($fontpath + 'poppins-bold-webfont.woff') format('woff2'), url($fontpath + 'poppins-bold-webfont.woff') format('woff'), url($fontpath + 'poppins-bold-webfont.ttf') format('truetype'), url($fontpath + 'poppins-bold-webfont.svg#poppinsbold') format('svg');
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'tagged';
  src: url($fontpath + 'Titillium-Light-webfont.eot');
  src: url($fontpath + 'Titillium-Light-webfont.eot?#iefix') format('embedded-opentype'), url($fontpath + 'Titillium-Light-webfont.woff') format('woff2'), url($fontpath + 'Titillium-Light-webfont.woff') format('woff'), url($fontpath + 'Titillium-Light-webfont.ttf') format('truetype'), url($fontpath + 'Titillium-Light-webfont.svg#robotolight') format('svg');
  font-weight: normal;
  font-style: normal; }
//#################################################################################################################RESET
* {
  margin: 0;
  padding: 0;
  border: none;
  outline: 0;
  -webkit-tab-highlight-color: rgba(0,0,0,0);
  font-size: 100%;
  line-height: 145%;
  font-style: normal;
  text-decoration: none;
  list-style: none;
  white-space: normal;
  font-family: 'Poppins', sans-serif;
  font-variant: normal;
  text-transform: none;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color_font; }

*:focus {
  outline: none; }
::-moz-focus-inner {
  border: 0;
  outline: 0; }

select::-moz-focus-inner {
  border: 0;
  outline: 0; }

select:-moz-focusring {
  outline: 0;
  color: transparent;
  text-shadow: 0 0 0 #000; }

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }
body {
  line-height: 1; }

blockquote, q {
  quotes: none; }

q:after,
q:before {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

article,aside,details,figcaption,figure,footer,header,menu,nav,section,main {
  display: block; }

audio,canvas,video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

svg:not(:root) {
  overflow: hidden; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

button:hover {
  cursor: pointer; }

input[type="text"], textarea, button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none; }

input, select {
  vertical-align: middle; }

textarea {
  -webkit-overflow-scroll: touch; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible; }

button[disabled],
input[disabled] {
  cursor: default; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button {
  background: transparent; }

::-webkit-input-placeholder {
  color: darken($color_gray, 15%);
  background: transparent; }
::-moz-input-placeholder {
  color: darken($color_gray, 15%);
  background: transparent; }
::-ms-input-placeholder {
  color: darken($color_gray, 15%);
  background: transparent; }
::input-placeholder {
  color: darken($color_gray, 15%);
  background: transparent; }
::-moz-selection {
  background: $color_font;
  color: $color_white; }
::-webkit-selection {
  background: $color_font;
  color: $color_white; }
::-ms-selection {
  background: $color_font;
  color: $color_white; }
::-o-selection {
  background: $color_font;
  color: $color_white; }
::selection {
  background: $color_font;
  color: $color_white; }
//###############################################################################################################PRESETS

//########Helperclasses
.wrp {
  //wrapperclass
  position: relative;
  margin: 0 auto; }

.hidden {
  display: none;
  opacity: 0;
  visibility: hidden; }

.clr {
  //single line clearfix
  display: block;
  clear: both; }

.cf {
  //multiblock clearfix
  zoom: 1; }
.cf:before,
.cf:after {
  content: "";
  display: table; }
.cf:after {
  clear: both; }

//########Headlines

h1,h2,h3,h4,h5,h6,.headline {
  display: block;
  color: $color_font;
  font-weight: 100;
  margin-bottom: $line-height; }

h1,h2,h3,.headline {
  font-size: 24px;
  margin-bottom: $line-height * 2; }
h4 {
  font-size: 20px; }
h5 {
  font-size: 18px; }
h6 {
  font-size: 18px; }

//########Typography

p {
  color: $color_font;
  font-size: 16px;
  line-height: $line-height;
  margin-bottom: $line-height; }

br {
  line-height: $line-height; }

ul {
  margin-bottom: $line-height;
  li {
    list-style: none;
    color: $color_font;
    font-size: 16px;
    line-height: $line-height;
    margin-left: 0px;
    /*&:before
      margin-top: $line-height / 3
      content: ''
      background: $color_font
      margin-right: 14px
      float: left
      width: 6px
      height: 6px*/ }

  &.link_list li {
    display: block;
    padding: 5px 0;
    background: $color_gray;
    clear: both;
    &:after {
      clear: both;
      content: ''; }
    &:before {
      display: none; }
    .btn {
      float: right;
      margin: 0 !important; }
    &:nth-of-type(2n+2) {
      background: $color_white; }
    a {
      display: block; } } }


.highlight {
  color: $color_highlight;
  &.success {
    color: $color_success !important; }
  &.error {
    color: $color_error !important; } }

a {

  color: $color_blue;
  &:hover {
    color: darken($color_blue, 10%);
    text-decoration: underline; } }

a::-webkit-selection {
  color: $color_white;
  background: $color_blue; }

a::-moz-selection {
  color: $color_white;
  background: $color_blue; }

a::-ms-selection {
  color: $color_white;
  background: $color_blue; }

a::selection {
  color: $color_white;
  background: $color_blue; }

strong:not([class="headline"]), b {
  font-family: 'bold';
  font-weight: 300;
  font-style: normal;
  color: $color_font;
  font-size: 14px; }

span.delete {
  color: #d70000;
  display: inline-block;
  padding-left: 25px; }
a.delete {
  border: 1px solid #d70000;
  padding: 10px 10px 5px 10px;
  border-radius: 20px;
  filter: grayscale(0%);
  display: inline-block;
  margin-bottom: 10px;
  &:hover {
    filter: grayscale(100%); } }

em, i {
  color: $color_font;
  font-size: 14px;
  color: lighten($color_font, 15%); }
hr {
  margin: $line-height 0;
  border-bottom: solid 1px lighten($color_font, 40%); }

img {
  //image clearfix
  line-height: 0;
  vertical-align: middle;
  display: block;
  -ms-interpolation-mode: bicubic; }

#offcanvas {
  display: none; }

@-webkit-keyframes popIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(1,1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.2,1.2); }
  100% {
    -webkit-transform: scale(1,1); } }

@-moz-keyframes popIn {
  0% {
    opacity: 0;
    -moz-transform: scale(1,1); }
  50% {
    opacity: 1;
    -moz-transform: scale(1.2,1.2); }
  100% {
    -moz-transform: scale(1,1); } }

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(1,1); }
  50% {
    opacity: 1;
    transform: scale(1.2,1.2); }
  100% {
    transform: scale(1,1); } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.leadprint-brand {
  position: relative;
  z-index: 1;
  float: right;
  line-height: 60px;
  font-size: 12px;
  color: $color_white;
  margin-right: 60px; }
