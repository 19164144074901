.mobil {
  display: none; }
#cms-bar {
  .col-3 {
    width: 33.3333%;
    float: left;
    background-size: contain;
    position: relative;
    img {
      max-width: 100%;
      height: auto; }
    .batch {
      position: absolute;
      display: inline-block;
      padding: 3px 5px;
      background-color: #000000;
      color: #ffffff;
      font-size: 11px;
      left: 50px;
      bottom: 40%;
      font-weight: 500; }
    h3 {
      position: absolute;
      color: $color_white;
      text-transform: uppercase;
      left: 50px;
      bottom: 10%;
      font-size: 2.5vw;
      font-weight: 800;
      line-height: 2.3vw;
      letter-spacing: -0.08em;
      max-width: 80%;
      small {
        font-size: 0.8vw;
        color: $color_white;
        text-transform: uppercase;
        letter-spacing: normal; } }
    .btn {
      position: absolute;
      bottom: 15px;
      left: 50px;
      display: block;
      background-color: rgba(255,255,255,0.2);
      height: 50px;
      line-height: 50px;
      border: 0px solid #fff;
      border-radius: 25px;
      width: 160px;
      text-align: center;
      -webkit-box-shadow: 0px 0px 32px -4px rgba(0,0,0,0.1);
      -moz-box-shadow: 0px 0px 32px -4px rgba(0,0,0,0.1);
      box-shadow: 0px 0px 32px -4px rgba(0,0,0,0.1);
      font-size: 14px;
      &:hover {
        background-color: rgba(255,255,255,0.25);
        -webkit-box-shadow: 0px 0px 32px -4px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 0px 32px -4px rgba(0,0,0,0.4);
        box-shadow: 0px 0px 32px -4px rgba(0,0,0,0.4); } } } }
#info-bar {
  .col-3 {
    width: 33.3333%;
    float: left;
    background-size: contain;
    position: relative;
    .text {
      padding: 0 50px 10px 50px;
      .headline {
        font-size: 16px;
        margin: 65px 0 25px 0;
        color: $color_blue;
        font-weight: 400;
        &:before {
          margin-right: 10px; }
        &.versand:before {
          content: url("../assets/custom/icon_info-bar_standardversand.png"); }
        &.versand_express:before {
          content: url("../assets/custom/icon_info-bar_expressversand.png"); }
        &.selbstabholung:before {
          content: url("../assets/custom/icon_info-bar_selbstabholung.png"); }
        &.selbstabholung_ex:before {
          content: url("../assets/custom/icon_info-bar_selbstabholung-express.png"); }
        &.bestellung:before {
          content: url("../assets/custom/icon_info-bar_bestellung.png"); } }
      strong {
        color: $color_blue;
        font-size: 12px;
        font-weight: 400; }
      p {
        font-size: 12px;
        line-height: 22px; } }
    h3 {
      position: relative;
      text-transform: uppercase;
      width: 145px;
      display: block;
      height: 25px;
      line-height: 25px;
      margin: -12px auto;
      font-size: 10pt;
      font-weight: 300;
      text-align: c&nter;
      background-color: $color_blue;
      color: $color_white;
      text-align: center;
      &.white {
        background-color: $color_white !important;
        color: $color_blue; } }
    &.blue {
      background-color: $color_blue;
      .text {
        .headline,
        p,
        strong {
          color: $color_white; } } }
    img {
      max-width: 100%;
      height: auto; } } }

.start {
  background: #fff !important;
  color: #2943c4;
  h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 30px;
    font-weight: 400;
    color: #111111;
    text-align: center;
    border-bottom: 1px solid #e6e6e6;
    padding: 50px 0;
    margin: 0; }
  article {
    width: 67%;
    padding: 0 50px 80px 50px;
    float: left;
    box-sizing: border-box;
    border-left:  1px solid #e6e6e6;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.03em;
    text-align: justify;
    .rte_container {
      line-height: 23px; }
    h2 {
      font-family: 'Oswald', sans-serif;
      font-size: 20px;
      font-weight: 400;
      color: #111111;
      padding: 40px 0 25px 0;
      margin: 0; } }
  aside {
    width: 33%;
    padding: 45px 15px 15px 15px;
    float: left;
    box-sizing: border-box;
    img {
      max-width: 100%;
      height: auto; } } }
.cms-header {
  background-color: #2681c0;
  margin-top: 16px;
  h1 {
    color: $color_white;
    font-weight: 700;
    font-size: 52px;
    letter-spacing: -0.1em;
    margin: 0; } }
.cms-text {
  font-size: 12px;
  .text {
    padding: 60px;
    font-size: 12px;
    p {
      font-size: 12px; } }
  .headline {
    margin: 0;
    background: #e4edf4;
    height: 50px;
    line-height: 50px;
    padding-left: 60px;
    font-size: 21px;
    font-weight: 600;
    color: $color_blue; } }
.header_bg {
  margin: 0;
  background: #e4edf4;
  height: 50px;
  line-height: 50px;
  padding-left: 60px;
  font-size: 21px;
  font-weight: 600;
  color: $color_blue;
  display: block; }

#configurator {
  label {
    white-space: normal;
    line-height: 18px;
    margin-top: 15px;
    font-size: 13px;
    vertical-align: middle; }
  label,
  h3,
  h4 {
    text-align: left; }

  h3, h4 {
    width: auto;
    color: $color_blue;
    font-weight: 300;
    font-family: inherit;
    font-size: 16px;
    &:after {
       display: none; } }

  select,
  input,
  textarea {
    font-family: inherit;
    border-radius: 25px !important;
    background-color: $color_white;
    border-color: #dbdbdb;
    height: 50px; }
  textarea {
    border-radius: 10px !important;
    height: 120px; }
  input:focus,
  select:focus,
  textarea:focus {
    color: #696969;
    background-color: #f7f7f7; }
  input.spare {
    border-top: 0px solid #dbdbdb; } }

.actionbar {
  margin: 0 auto;
  width: 50%;
  .success {
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    float: left !important;
    &:after {
      content: url("../assets/custom/icon_btn_forward.png");
      position: absolute;
      top: 3px;
      margin-left: 5px; }
    &:hover {
      background-color: $color_white;
      color: $color_blue;
      &:after {
        content: url("../assets/custom/icon_btn_forward_hover.png"); } }
    &.successYellow {
      &:hover {
        &:after {
          content: url("../assets/custom/icon_btn_forward_yellow_hover.png"); } } } }
  .back {
    height: 60px;
    line-height: 60px;
    border-radius: 30px;
    background-color: #dadada;
    margin-right: 35px;
    &:before {
      content: url("../assets/custom/icon_btn_back.png");
      position: absolute;
      top: 3px;
      margin-left: -15px; }
    &:hover {
      background-color: $color_white;
      color: #dadada;
      border-color: #dadada;
      &:before {
        content: url("../assets/custom/icon_btn_back_hover.png"); } } } }
#register {
  .actionbar {
    width: 100%;
    padding: 0 25px;
    .success {
      float: right !important;
      margin-right: 40px; } }
  section#customer_login {
    line-height: 40px; } }
button.btn.address.without_registration,
button.btn.coupon {
  background-color: $color_yellow;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  border-color: $color_white;
  color: $color_white;
  &:hover {
    background-color: $color_white;
    color: $color_yellow;
    border-color: $color_yellow; } }
button.btn.success.address.register {
  background-color: #c7c7c7;
  border-color: $color_white;
  height: 50px;
  line-height: 50px;
  border-radius: 25px;
  margin-top: 25px;
  &:hover {
    background-color: $color_white;
    color: #c7c7c7;
    border-color: #c7c7c7; } }
.coupon {
  .btn {
    position: relative;
    float: left;
    right: inherit;
    top: inherit; } }
#finish,
#account_tracking,
#pw_lost,
#address,
#contact,
#order_sample {
  .wrp {
    background-color: $color_white;
    h1 {
      margin: 0;
      background: #e4edf4;
      height: 50px;
      line-height: 50px;
      padding-left: 60px;
      font-size: 21px;
      font-weight: 600;
      color: $color_blue;
      box-sizing: border-box; }
    form,
    .text {
      padding: 0 60px; } } }
#finish {
  .hint {
    margin: 30px 60px 60px 60px; } }
#pw_lost {
  label {
    width: 240px; }
  .btn {
    margin-left: 240px; } }
#address {
  article, {
    width: 100%;
    padding: 0; } }

#account_tracking {
  table,
  ul,
  .total_value {
    padding-left: 60px !important; }
  td {
    padding: 0 !important; }
  li {
    font-size: 87.5%;
    line-height: 24px; }
  #assigned_articles {
    h3 {
      margin-bottom: 25px; }
    a {
      color: $color_lila;
      font-size: 87.5%;
      line-height: 24px;
      padding-left: 60px !important; } } }

form#tracking_search button[name="cmd_calc"] {
  top: 0px !important; }

#account_tracking {
  .col_left,
  .col_right {
    padding-left: 0px {
      padding-right: 0px; } } }


.widerruf_muster {
  border: 1px solid #000;
  width: 75%;
  margin: 0 auto;
  background-color: $color_gray;
  padding: 25px;
  margin-top: 25px; }

.cms-text {
  .versand_cms {
    width: 50%;
    float: left;
    border-top: 1px solid #e4edf4;
    border-bottom: none;
    min-height: 280px;
    box-sizing: border-box;
    padding: 50px 50px 50px 65px;
    min-height: 400px;
    &:nth-of-type(even) {
      border-left: 1px solid #e4edf4; }
    .img {
      width: 30%;
      float: left; }
    .text {
      width: 70%;
      float: left;
      box-sizing: border-box;
      padding: 0 25px; }
    .bez {
      font-size: 30px;
      font-weight: 700;
      color:  $color_blue;
      letter-spacing: -0.08em;
      line-height: 1.2em; }
    .price {
      font-size: 21px;
      font-weight: 300;
      color: $color_blue; }
    strong {
      font-weight: 600;
      color: $color_blue;
      text-transform: uppercase;
      display: inline-block;
      margin-top: 25px; } } }

.menu-item-search {
  width: 280px;
  float: left;
  padding-top: 5px;
  position: relative;
  form {
    input {
      width: 175px;
      border-radius: 5px !important; }
    &:before {
      content: url("../assets/custom/icon_search_loupe.png");
      position: absolute;
      left: -35px;
      top: 15px; } }
  button {
    margin-left: 10px;
    color: $color_blue;
    font-weight: 500;
    &:hover {
      color: $color_orange; } } }

#voucher {
  background-color: $color_white;
  .btn {
    bottom: 22%;
    right: 3%; } }
#product-detail {
  .input_small {
    float: left;
    input {
      width: 120px !important; } }
  .input_small.input_small_float {
    padding-left: 13px !important;
    label {
      width: 135px; } }
  form input:not(.btn),
  form select,
  form textarea {
    width: 390px; }
  form textarea {
    overflow-x: hidden; }
  .btn_calculation {
    background-color: #97afd2;
    width: 180px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    color: #000000;
    float: none;
    font-size: 11px;
    box-shadow: 0px 0px 32px -4px rgba(0, 0, 0, 0.1);
    &:before {
      content: ''; }
    &:hover {
      box-shadow: 0px 0px 32px -4px rgba(0, 0, 0, 0.4);
      transition: all 0.25s ease-out; } } }


#product-detail {
  #total_block {
    .pricecalc {
      .head {
        font-size: 16px;
        text-align: left;
        display: block;
        width: 100%;
        color: $color_blue;
        border-bottom: 1px solid #e4edf4;
        margin-bottom: 15px; } }
    ul.pricecalc,
    time,
    #delivery_countdown {
      margin-left: 10px; } } }
#product-detail {
  #delivery {
    padding: 80px 25px 15px 25px;
    margin-bottom: 30px;
    ul {
      margin-bottom: 0; }
    .btn_calculation {
      height: 40px;
      line-height: 40px;
      display: block;
      font-size: 11px;
      width: 120px;
      position: absolute;
      bottom: -20px;
      left: 50%;
      margin-left: -60px;
      &:hover {
        cursor: pointer; } }
    .head {
      font-size: 16px;
      text-align: left;
      display: block;
      width: 100%;
      color: $color_blue; }
    h3 {
      position: absolute;
      box-sizing: border-box;
      top: 0;
      left: 0;
      right: 0;
      background: $color_blue;
      height: 50px;
      line-height: 50px;
      padding: 0 0 0 25px;
      font-size: 21px;
      font-weight: 600;
      color: $color_white;
      margin: 0;
      font-family: 'regular';
      width: auto;
      text-align: left;
      &:after {
        content: none; } } } }

#detail_infobar_text {
  padding: 0!important; }


.slick_slider {
  width: 75%;
  float: right; }

.slick-slide {
  margin: 0px 20px; }

.slick-slide img {
  width: 100%; }

.slick-prev:before,
.slick-next:before {
  color: black; }
.rte_container {
  img {
    max-width: 100%;
    height: auto; } }

#upload {
  #upload_section {
    #upload_email {
      .email {
        border-color: darken($color_yellow, 7%);
        background: $color_yellow;
        border-radius: 25px;
        height: 45px;
        line-height: 45px;
        width: 48%;
        &:hover,
        &.hover {
          color: $color_yellow;
          border-color: $color_yellow;
          background: none;
          @include transition(); }
        &:active,
        &.active {
          border-color: darken($color_yellow, 15%);
          background: darken($color_yellow, 10%); }
        &.active {
          -webkit-animation: ripple 0.1s 1 linear;
          -moz-animation: ripple 0.1s 1 linear;
          animation: ripple 0.1s 1 linear;
          @include transition();
          .anim.loading {
            border-color: darken($color_yellow, 21%);
            border-bottom-color: lighten($color_yellow, 10%);
            margin-top: 3px; } } } } } }

@media screen and (max-width: 600px) {
  #upload {
    #upload_section {
      #upload_email {
        .email {
          font-size: 14px;
          width: 100%; } } } } }


#customBreadcrumb {
  box-shadow: none!important;
  padding: 10px 0;
  background-color: $color_white!important;
  ul {
    width: 100%;
    li {
      list-style-type: none;
      float: left;
      width: 20%;
      padding: 15px 30px;
      font-size: 18px;
      box-sizing: border-box;
      color: #fff;
      height: 50px;
      position: relative;
      background: url(../assets/cart_breadcrumb_default.png) no-repeat right center #dadada;
      &:last-child {
        background: #dadada;
        background-image: none; }
      &.unactive {
        background: url(../assets/cart_breadcrumb_unactive.png) no-repeat right center #5b73ea; }
      &.preactive {
        background: url(../assets/cart_breadcrumb_preactive.png) no-repeat right center #5b73ea; }
      &.active {
        background: url(../assets/cart_breadcrumb_active.png) no-repeat right center $color_blue; }
      &:last-child.active {
        background: $color_blue;
        background-image: none; } } } }

@media screen and (max-width: 1600px) {
  #customBreadcrumb {
    ul {
      li {
        font-size: 14px;
        padding: 15px 20px; } } } }

@media screen and (max-width: 1280px) {
  #customBreadcrumb {
    ul {
      li {
        font-size: 12px; } } } }

@media screen and (max-width: 960px) {
  #customBreadcrumb {
    ul {
      li {
        font-size: 10px;
        padding: 10px 15px;
        height: 42px; } } } }

@media screen and (max-width: 780px) {
  #customBreadcrumb {
    display: none; } }

#btn_calculate {
  width: 20px;
  min-width: 20px;
  top: 5px;
  right: 9px;
  border-radius: 43px;
  &:before {
    color: $color_blue; } }

form label.radio_img {
  width: 45px !important;
  height: 45px !important;
  overflow: hidden;
  &:not(:last-of-type) {
    margin-right: 10px; } }

form label.radio_img .mask {
  width: 45px !important;
  height: 45px !important;
  display: block;
  background-image: url(../assets/radio_img_mask.svg);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer; }

form label.radio_img input:checked + .mask {
  background-image: url(../assets/radio_img_mask_active.svg); }

form label.radio_img img {
  position: absolute;
  top: -200px !important;
  left: -140px !important; }
