.upload_field {
  progress {
    width: 100%;
    display: block; }
  .text_percent {
    color: darken($color_success, 15%);
    text-align: right;
    display: block;
    margin: 5px 0 15px 0; }

  .text_filename {
    font-size: 14px;
    line-height: 18px;
    display: block; } }
