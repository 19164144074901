.kdz-modal {
  top: 0;
  left:  0;
  right:  0;
  position: fixed;
  z-index: 10000;
  bottom:  0;
  background: rgba(0,0,0,0.6);
  .modal-big {
    top: 50%;
    position: absolute;
    width: 80rem;
    height: auto;
    left: 50%;
    -webkit-transform: translate(0);
    transform: translate(-50%, -50%);
    overflow: visible; }
  .modal-lg {
    top: 50%;
    position: absolute;
    width: 64rem;
    height:  auto;
    left: 50%;
    -webkit-transform: translate(0);
    transform: translate(-50%, -50%);
    overflow: visible; }
  .modal-content {
    border-radius: 0;
    border: 4px solid rgba(35,186,235,.3);
    background:  transparent;
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0,0,0,.5);
    max-height:  none;
    .modal-header {
      border-bottom:  0;
      background: transparent; }
    .modal-body {
      padding: 15px; } } }
.kdz-bindungstabelle {
  p {
    font-size: 14px; }
  h3 {
    text-align: left;
    margin: 0 !important;
    padding: 0;
    font-size: 22px;
    font-weight: 300;
    font-family: "Open Sans", sans-serif;
    line-height: 33px;
    &::after {
      content: none;
      width: 0;
      display: none;
      border: none;
      background: none; } }
  td {
    border: none;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd; }
  th {
    border-bottom: 2px solid #ddd;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 0;
    white-space: normal;
    width: 110px;
    &.filler {
      width: auto; }
    p {
      text-align: left;
      padding-top: 12px; }
    img {
      width: 110px;
      height: auto;
      display: block;
      max-width: none; } }
  tbody {
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    tr:nth-of-type(odd) {
      background-color: #f9f9f9; } } }
.kdz-modal-link {
  cursor: pointer;
  display: block;
  width: 366px;
  margin-bottom: 16px;
  padding-bottom: 0;
  padding-top: 8px;
  padding-left: 187px;
  float: left;
  color: $color_blue;
  small {
    color: $color_blue; }
  &:hover {
    color: darken($color_blue, 10%);
    small {
      color: darken($color_blue, 10%); } } }

@media screen and (max-width: 600px) {
  .kdz-modal-link {
    width: 95%;
    padding-left: 0; } }

@media screen and (max-width: 500px) {
  .kdz-modal-link {
    width: 90%;
    padding-left: 0; } }
