#finish {
  margin-bottom: 80px;
  .item {
    width: 49%;
    background: $color_white;
    padding: 10px;
    line-height: 40px;
    border-radius: 2px;
    color: $color_font;
    float: left;
    margin-right: 2%;
    box-sizing: border-box;
    &:nth-of-type(2n+2) {
      margin-right: 0; }
    .label {
      line-height: 40px;
      float: left;
      display: block;
      margin: 0 20px 0 0;
      height: 40px; }
    .btn {
      width: 240px;
      float: left;
      margin: 0; } } }

