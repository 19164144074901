.kdz-container {
  .btn {
    display: inline-block;
    border-radius: 45px;
    width: 190px;
    height: 60px;
    line-height: 55px;
    font-size: 16px;
    font-weight: 500;
    color: black;
    border: 3px solid #97afd2; }
  .btn.kdz-yellow {
    display: inline-block;
    border-radius: 45px;
    width: 190px;
    height: 60px;
    line-height: 55px;
    font-size: 16px;
    font-weight: 500;
    color: black;
    background: #fcea0f;
    background: -moz-linear-gradient(top, #fcea0f 1%, #f6bd31 100%);
    background: -webkit-linear-gradient(top, #fcea0f 1%, #f6bd31 100%);
    background: linear-gradient(to bottom, #fcea0f 1%, #f6bd31 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fcea0f', endColorstr='#f6bd31',GradientType=0 );
    border: 3px solid #fbce4a;
    &:hover {
      background: none;
      color: #fbce4a !important;
      transition: all 0.25s ease-out; } }
  .kdz--main--content {
    .kdz--main--content--header {
      text-align: left;
      padding-top: 16px;
      padding-bottom: 16px;
      padding-right: 32px;
      padding-left: 32px;
      background: $color_blue;
      //background: #ededed
      //background: -moz-linear-gradient(left, #ededed 16%, #2681c0 46%, #2681c0 100%)
      //background: -webkit-linear-gradient(left, #ededed 16%,#2681c0 46%,#2681c0 100%)
      //background: linear-gradient(to right, #ededed 16%,#2681c0 46%,#2681c0 100%)
      //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#2681c0',GradientType=1 )
      h1 {
        color: white;
        font-weight: 700;
        width: 100%;
        margin-left: auto;
        margin-bottom: 0; } }
    .kdz--main--content--wrapper {
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      background: white;
      .kdz--main--content--image {
        position: relative;
        display: block;
        width: 50%;
        img {
          width: 100%;
          max-width: 100%;
          display: block; }
        .btn.kdz-yellow {
          position: absolute;
          left: 10%;
          bottom: 10%; } }
      .kdz--main--content--text {
        padding-top: 20px;
        padding-right: 20px;
        padding-left: 20px;
        position: relative;
        width: 46%;
        font-size: 14px; } } }
  h3 {
    color: $color_blue;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 24px; }
  p {
    font-size: 16px; }
  ul {
    font-size: 16px;
    position: relative;
    margin-left: 5px;
    li {
      font-size: 16px;
      color: $color_blue;
      padding-bottom: 16px;
      padding-left: 25px;
      position: relative;
      font-weight: 600;
      &::before {
        position: absolute;
        left: 0;
        top: -4px;
        content: '+';
        font-weight: 500;
        display: inline-block;
        padding-right: 16px;
        font-size: 20px;
        margin-top: 4px; } } } }
.kdz--main--content--options {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 20px;
  span {
    margin-left: 20px;
    color: $color_blue; }
  .color {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    img {
      margin-left: 10px;
      margin-right: 10px; } } }
.kdz--main--content-gallery {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  padding-bottom: 20px;
  padding-top: 20px;
  .gallery--header {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-left: 20px;
    img {
      margin-right: 20px;
      width: 60px; }
    span {
      color: $color_orange;
      font-weight: 700;
      font-size: 24px; } }
  .gallery--content {
    margin-right: 20px;
    box-shadow: 10px 0px 7px 4px rgba(0,0,0, 0.10);
    -moz-box-shadow: 10px 0px 7px 4px rgba(0,0,0, 0.10);
    -webkit-box-shadow: 10px 0px 7px 4px rgba(0,0,0, 0.10);
    padding: 20px;
    background: white;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    img {
      padding-left: 20px;
      width: 190px; } } }

.kdz--main--content-video {
  max-width: 1200px;
  margin-right: auto;
  margin-left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px;
  padding-right: 0;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
  .video--left {
    width: 50%;
    padding: 20px;
    box-shadow: -10px 1px 5px 4px rgba(0,0,0, 0.11);
    -moz-box-shadow: -10px 1px 5px 4px rgba(0,0,0, 0.11);
    -webkit-box-shadow: -10px 1px 5px 4px rgba(0,0,0, 0.11);
    .video--left--inner {
      text-align: center;
      padding: 20px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
    span {
      color: white;
      font-size: 14px; }
    h2 {
      font-weight: 700;
      color: white;
      margin-bottom: 20px; }
    a.btn.video {
      background: rgba(255,255,255,0.3);
      font-size: 14px; } }
  .video--right {
    width: 40%;
    text-align: right;
    padding: 20px;
    .video--header {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      align-items: center;
      margin-bottom: 20px;
      img {
        width: 60px; }
      span {
        color: $color_orange;
        margin-right: 20px;
        font-weight: 700;
        font-size: 24px; } } } }
.kdz--product--content {
  padding: 40px;
  span.kdz--header-sub {
    width: 100%;
    color: $color_orange;
    font-size: 24px;
    font-weight: 700; }
  h1 {
    width: 100%;
    color: $color_blue;
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 0;
    line-height: 40px; }
  .product--slogan {
    position: relative;
    background: $color_blue;
    padding: 10px;
    max-width: 450px;
    color: white;
    font-weight: 700;
    font-size: 18px;
    margin-left: 56px;
    padding-left: 100px;
    margin-top: 40px;
    margin-bottom: 30px;
    &::before {
      content: url("../assets/custom/kdz/mann.png");
      width: 100%;
      height: auto;
      position: absolute;
      left: -58px;
      bottom: -7px; } }

  .product--content--header {
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    img {
      width: 70px;
      display: block;
      position: relative; }
    .product--content--header-wrapper {
      padding-left: 20px; } }

  .product--content--main {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    .main--left {
      width: 35%;
      h1 {
        margin-bottom: 20px; } }
    .main-right {
      width: 60%;
      margin-left: 5%;
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      .kdz--product--image--main {
        width: 70%;
        img {
          width: 100%; } }
      .kdz--product--image--gallery {
        width: 30%;
        padding-left: 40px;
        .gallery--item {
          width: 125px;
          border: 4px solid $color_blue;
          border-bottom: none;
          position: relative;
          cursor: pointer;
          &:last-of-type {
            border-bottom: 4px solid $color_blue; }
          &.active {
            &::before {
              transition: all ease 0.5s;
              content: '';
              position: absolute;
              left: -17.3px;
              top: 50%;
              transform: translate(-50%);
              width: 0;
              height: 0;
              -webkit-transform: rotate(360deg);
              border-style: solid;
              border-width: 10px 17.3px 10px 0;
              border-color: transparent $color_blue transparent transparent; } }
          img {
            height: 175px;
            width: 125px; } } } } } }
.kdz--content--options {
  padding-top: 20px;
  padding-bottom: 20px;
  .kdz--content--options--item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    margin-bottom: 20px;
    span {
      color: $color_blue;
      width: 40%;
      font-size: 14px; }
    .color {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      img {
        margin-left: 10px;
        margin-right: 10px;
        width: 35px;
        cursor: pointer; } } } }

.product--content--options {
  margin-top: 40px;
  .content--options--menu {
    ul {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      li {
        padding: 4px;
        margin-right: 20px;
        margin-bottom: 5px;
        a {
          &::after {
            position: absolute;
            left: 0;
            right: 0;
            margin-top: 10px;
            display: block;
            content: ' ';
            width: 100%;
            height: 4px;
            background: white; } }
        &::before {
          content: none; }
        &.active {
          background: $color_blue;
          a {
            position: relative;
            color: white;
            &::after {
              position: absolute;
              left: 0;
              right: 0;
              margin-top: 10px;
              display: block;
              content: ' ';
              width: 100%;
              height: 4px;
              background: $color_orange; } } } } } } }
.is--blue {
  color: $color_blue; }
#opt-option {
  margin-top: 50px;
  .content--options--overview {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    align-content: flex-start;
    flex-wrap: wrap;
    .options--overview--item {
      width: 23%;
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      align-items: flex-start;
      justify-content: flex-start;
      .overview--item--image {
        width: 100%; }
      .overview--item--content {
        width: 100%;
        padding: 10px;
        p {
          text-align: justify; } }
      .overview--item--action {
        margin-top: auto;
        margin-bottom: 0;
        width: 100%;
        padding: 10px;
        a {
          width: 100%;
          height: 40px;
          line-height: 35px; } } } } }

#opt-paper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-top: 40px;
  .paper--left {
    width: 70%;
    .paper--image {
      float: left;
      max-width: 450px; }
    .paper--options {
      margin-left: 460px;
      margin-top: 330px;
      margin-bottom: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      .paper--option--item {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        font-size: 16px;
        line-height: 18px;
        font-weight: 600;
        margin-left: 20px;
        width: 60px;
        height: 60px;
        background-image: url('../assets/custom/kdz/box.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        color: $color_blue;
        position: relative;
        &.active {
          background-image: url('../assets/custom/kdz/box-gelb.png');
          color: white;
          &::after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -10px;
            margin-top: 10px;
            display: block;
            content: ' ';
            width: 100%;
            height: 4px;
            background: #f6bd31; } } } }
    // border-bottom: 4px solid #f6bd31
    .papier--text {
      margin-left: 490px;
      margin-top: 30px;
      h4 {
        color: $color_blue;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
        sup {
          color: $color_blue;
          font-size: 18px; } }
      p {
        font-size: 14px; } } }
  .paper-right {
    text-align: right;
    p {
      font-size: 14px; }
    h1 {
      margin-bottom: 40px; }
    .oko-label {
      margin-top: 60px;
      .oko-label--header {
        display: flex;
        justify-content: flex-end;
        align-content: center;
        align-items: center;
        margin-bottom: 20px;
        img {
          width: 60px; }
        span {
          color: $color_orange;
          margin-right: 20px;
          font-weight: 700;
          font-size: 24px; } }
      .oko-label--content {
        img {
          margin-left: auto;
          display: block; } } } } }
@media screen and (max-width: 1600px) {
  .kdz--main--content-gallery {
    justify-content: flex-start;
    .gallery--header {
      margin-bottom: 20px; } } }
@media screen and (max-width: 1200px) {
  .kdz-container {
    .kdz--main--content {
      .kdz--main--content--wrapper {
        .kdz--main--content--image {
          width: 100%; }
        .kdz--main--content--text {
          width: 100%; } } } }
  .kdz--main--content--options {
    justify-content: flex-start;
    .color {
      margin-bottom: 10px; } }
  .kdz--main--content-gallery {
    justify-content: flex-start;
    .gallery--content {
      margin-right: 0;
      justify-content: flex-start;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      a {
        width: 25%;
        margin-bottom: 10px;
        margin-right: 20px; }
      img {
        width: 100%;
        padding: 0; } } }
  .kdz--main--content-video {
    padding-left: 0;
    .video--left {
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      width: 100%; }
    .video--right {
      width: 100%;
      text-align: right; } }

  .kdz--product--content {
    padding: 10px;
    .product--slogan {
      padding-left: 100px;
      padding-right: 10px; }


    .product--content--header {
      .product--content--header-wrapper {
        padding-left: 10px; }
      img {
        display: block; } }
    .product--content--main {
      .main--left {
        width: 100%; }
      .main-right {
        width: 100%;
        margin-left: 0; } } }
  #opt-option {
    .content--options--overview {
      flex-wrap: wrap;
      .options--overview--item {
        width: 46%; } } }
  #opt-paper {
    flex-wrap: wrap;
    .paper--left {
      width: 100%;
      .paper--image {
        width: 100%;
        float: none; }
      .paper--options {
        margin-left: 0;
        margin-top: 0; }
      .papier--text {
        margin-left: 0;
        margin-top: 20px; } }
    .paper-right {
      width: 100%; } } }
@media screen and (max-width: 512px) {
  .kdz-container {
    .kdz--main--content {
      .kdz--main--content--wrapper {
        .kdz--main--content--image {
          width: 100%; }
        .kdz--main--content--text {
          width: 100%; } } } }
  .kdz--main--content--options {
    justify-content: flex-start;
    .color {
      margin-bottom: 10px; } }
  .kdz--main--content-gallery {
    justify-content: flex-start;
    .gallery--content {
      margin-right: 0;
      justify-content: flex-start;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      a {
        width: 100%;
        margin-bottom: 10px; }
      img {
        width: 100%;
        padding: 0; } } }
  .kdz--main--content-video {
    padding-left: 0;
    .video--left {
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      width: 100%; }
    .video--right {
      width: 100%;
      text-align: left; } }

  .kdz--product--content {
    padding: 10px;
    .product--slogan {
      padding-left: 10px;
      margin-left: 0;
      &::before {
        content: none; } }

    .product--content--header {
      .product--content--header-wrapper {
        padding-left: 0; }
      img {
        display: none; } }
    .product--content--main {
      .main--left {
        width: 100%; }
      .main-right {
        width: 100%;
        margin-left: 0;
        flex-wrap: wrap;
        .kdz--product--image--main {
          width: 100%; }
        .kdz--product--image--gallery {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-content: stretch;
          align-items: flex-start;
          padding-left: 0;
          &.hidden {
            display: none; }
          .gallery--item {
            margin-top: 20px;
            border-bottom: 4px solid #23baeb;
            height: 130px;
            img {
              height: 130px !important; }
            &.active {
              &::before {
                top: -17.3px;
                left: 50%;
                transform: translate(0, -50%);
                width: 0px;
                height: 0px;
                -webkit-transform: rotate(360deg);
                border-style: solid;
                border-width: 0 10px 17.3px 10px;
                border-color: transparent transparent #23baeb transparent; } } } } } } }
  #opt-option {
    .content--options--overview {
      flex-wrap: wrap;
      .options--overview--item {
        width: 100%; } } }
  #opt-paper {
    flex-wrap: wrap;
    .paper--left {
      width: 100%;
      .paper--image {
        width: 100%;
        float: none; }
      .paper--options {
        margin-left: 0;
        margin-top: 0; }
      .papier--text {
        margin-left: 0;
        margin-top: 20px; } }
    .paper-right {
      width: 100%; } } }
