.modal {
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  position: absolute;
  background: $color_white;
  border: solid 1px lighten($color_font, 40%);
  padding: 10px;
  z-index: 5;
  display: none;
  &.active {
    display: block; }

  &.login {
    width: 260px;
    min-height: 90px;
    margin: -300px 0 0 -45px;
    position: fixed;
    left: 50%;
    display: block;
    opacity: 0;
    top: -200%;
    box-shadow: 0 0 3px rgba(0,0,0,.2);
    -webkit-animation: loginmessage 4.5s ease-out 1;
    -moz-animation: loginmessage 4.5s ease-out 1;
    -ms-animation: loginmessage 4.5s ease-out 1;
    animation: loginmessage 4.5s ease-out 1;

    h1 {
      margin-bottom: 10px; }
    p {
      margin-bottom: 0; } } }




#overlay {
  cursor: not-allowed;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
  z-index: 4;
  position: fixed;
  top: 200%;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  background: rgba($color_font,0.5);
  @include transition(opacity);
  opacity: 0;
  &.active {
    @include transition(opacity);
    opacity: 1;
    top: 0; } }

@-webkit-keyframes loginmessage {
  0% {
    top: 0;
    opacity: 0; }

  20% {
    top: 50%;
    opacity: 1; }

  60% {
    top: 50%;
    opacity: 1; }

  100% {
    top: -200%;
    opacity: 0; } }

@-moz-keyframes loginmessage {
  0% {
    top: 0;
    opacity: 0; }

  20% {
    top: 50%;
    opacity: 1; }

  60% {
    top: 50%;
    opacity: 1; }

  100% {
    top: -200%;
    opacity: 0; } }

@-ms-keyframes loginmessage {
  0% {
    top: 0;
    opacity: 0; }

  20% {
    top: 50%;
    opacity: 1; }

  60% {
    top: 50%;
    opacity: 1; }

  100% {
    top: -200%;
    opacity: 0; } }

@keyframes loginmessage {
  0% {
    top: 0;
    opacity: 0; }

  20% {
    top: 50%;
    opacity: 1; }

  60% {
    top: 50%;
    opacity: 1; }

  100% {
    top: -200%;
    opacity: 0; } }
.modal-cart {
  display: none;
  position: absolute;
  z-index: 5; }
.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white; }
.modal-body {
  padding: 2px 16px; }
.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white; }
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  max-height: 500px;
  overflow-y: scroll;
  #modal-text {
    position: relative;
    article {
      padding-left: 0; } }
  .close {
    float: right;
    color: #aaaaaa;
    font-size: 28px;
    font-weight: bold;
    content: "x";
    z-index: 6; } }
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer; }


@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }

  to {
    top: 0;
    opacity: 1; } }

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0; }

  to {
    top: 0;
    opacity: 1; } }
