/*######################################################################################################################
  ######################################################################################################################
  #########################################################Button#######################################################
  ######################################################################################################################
  ###################################################################################################################### */

.btn {
  position: relative;
  display: inline-block;
  padding: 0 10px;
  border: solid 1px darken($color_highlight, 7%);
  background: #97afd2;
  border-radius: 5px;
  min-width: $grid * 4;
  height: 40px;
  cursor: pointer;
  color: $color_white;
  margin-bottom: $line-height;
  box-sizing: border-box;
  font-size: 18px;
  text-align: center;
  line-height: 40px;
  @include transition();
  &:hover,
  &.hover {
    color: $color_white;
    border-color: $color_highlight;
    background: #E4EDF4;
    @include transition(); }
  &:active,
  &.active {
    border-color: darken($color_highlight, 21%);
    background: #E4EDF4; }
  &.active {
    -webkit-animation: ripple 0.1s 1 linear;
    -moz-animation: ripple 0.1s 1 linear;
    animation: ripple 0.1s 1 linear;
    @include transition();
    .anim.loading {
      border-color: darken($color_highlight, 21%);
      border-bottom-color: lighten($color_highlight, 10%); } }
  &.success {
    border-color: darken($color_blue, 7%);
    background: $color_blue;
    border-radius: 25px;
    height: 50px;
    line-height: 50px;
    width: auto;
    &:hover,
    &.hover {
      color: $color_blue;
      border-color: $color_blue;
      background: none;
      @include transition(); }
    &:active,
    &.active {
      border-color: darken($color_blue, 15%);
      background: darken($color_blue, 10%); }
    &.active {
      -webkit-animation: ripple 0.1s 1 linear;
      -moz-animation: ripple 0.1s 1 linear;
      animation: ripple 0.1s 1 linear;
      @include transition();
      .anim.loading {
        border-color: darken($color_blue, 21%);
        border-bottom-color: lighten($color_blue, 10%);
        margin-top: 3px; } }
    &.successYellow {
      border-color: darken($color_yellow, 7%);
      background: $color_yellow;
      border-radius: 100px;
      height: 60px;
      line-height: 60px;
      padding: 0 20px;
      &:hover,
      &.hover {
        color: $color_yellow;
        border-color: $color_yellow;
        background: none;
        @include transition(); }
      &:active,
      &.active {
        border-color: darken($color_yellow, 15%);
        background: darken($color_yellow, 10%); }
      &.active {
        -webkit-animation: ripple 0.1s 1 linear;
        -moz-animation: ripple 0.1s 1 linear;
        animation: ripple 0.1s 1 linear;
        @include transition();
        .anim.loading {
          border-color: darken($color_yellow, 21%);
          border-bottom-color: lighten($color_yellow, 10%);
          margin-top: 3px; } } } }
  &.error {
    border-color: darken($color_error, 10%);
    background: $color_error;
    &:hover,
    &.hover {
      color: $color_white;
      border-color: desaturate($color_error, 10%);
      background: lighten($color_error, 10%);
      @include transition(); }
    &:active,
    &.active {
      border-color: darken($color_error, 21%);
      background: darken($color_error, 7%); }
    &.active {
      -webkit-animation: ripple 0.1s 1 linear;
      -moz-animation: ripple 0.1s 1 linear;
      animation: ripple 0.1s 1 linear;
      @include transition();
      .anim.loading {
        border-color: darken($color_error, 21%);
        border-bottom-color: lighten($color_error, 10%); } } }
  &.slider {
    display: block;
    border-radius: 45px;
    width: 240px;
    height: 80px;
    line-height: 80px;
    font-size: 21px;
    font-weight: 500; }


  &.yellow {
    border-color: darken($color_yellow, 7%);
    color: $color_blue;
    background: -moz-linear-gradient(top, rgba(254,219,94,1) 0%, rgba(246,189,48,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,219,94,1)), color-stop(100%, rgba(246,189,48,1)));
    background: -webkit-linear-gradient(top, rgba(254,219,94,1) 0%, rgba(246,189,48,1) 100%);
    background: -o-linear-gradient(top, rgba(254,219,94,1) 0%, rgba(246,189,48,1) 100%);
    background: -ms-linear-gradient(top, rgba(254,219,94,1) 0%, rgba(246,189,48,1) 100%);
    background: linear-gradient(to bottom, rgba(254,219,94,1) 0%, rgba(246,189,48,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fedb5e', endColorstr='#f6bd30', GradientType=0 );
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    box-sizing: border-box;
    &:hover,
    &.hover {
      color: $color_yellow;
      background: none;
      border: 3px solid $color_yellow;
      line-height: 75px;
      @include transition(); }
    &:active,
    &.active {
      border-color: darken($color_success, 15%); }
    &.active {
      -webkit-animation: ripple 0.1s 1 linear;
      -moz-animation: ripple 0.1s 1 linear;
      animation: ripple 0.1s 1 linear;
      @include transition();
      .anim.loading {
        border-color: darken($color_yellow, 21%);
        border-bottom-color: lighten($color_yellow, 10%); } } }
  &.btn_tradegroup {
    border-radius: 45px;
    width: auto;
    height: 40px;
    line-height: 40px;
    font-size: 11px;
    font-weight: 500;
    position: absolute;
    bottom: 10%;
    left: 5%;
    margin-bottom: 0;
    &.yellow {
      display: block;
      border-radius: 45px;
      width: 190px;
      height: 60px;
      line-height: 55px;
      font-size: 16px;
      font-weight: 500;
      color: #000 !important;
      position: absolute;
      bottom: 5%;
      right: 9%;
      left: auto;
      border: 3px solid $color_yellow;
      &:hover {
        color: $color_yellow !important;
        font-size: 16px; } } }
  &.blue {
    border-color: darken($color_blue, 7%);
    color: $color_white;
    background: rgba($color_blue, 1);
    &:hover,
    &.hover {
      background: rgba(58, 84, 214, 0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px transparent;
      color: $color_blue;
      @include transition(); }
    &:active,
    &.active {
      border-color: darken($color_blue, 15%); }
    &.active {
      -webkit-animation: ripple 0.1s 1 linear;
      -moz-animation: ripple 0.1s 1 linear;
      animation: ripple 0.1s 1 linear;
      @include transition();
      .anim.loading {
        border-color: darken($color_blue, 21%);
        border-bottom-color: lighten($color_blue, 10%); } } }

  &.delete {
    border-color: darken($color_error, 10%);
    background: transparent;
    color: $color_error;
    &:hover,
    &.hover {
      color: $color_white;
      border-color: desaturate($color_error, 10%);
      background: lighten($color_error, 10%);
      @include transition(); }
    &:active,
    &.active {
      border-color: darken($color_error, 21%);
      background: darken($color_error, 7%); }
    &.active {
      -webkit-animation: ripple 0.1s 1 linear;
      -moz-animation: ripple 0.1s 1 linear;
      animation: ripple 0.1s 1 linear;
      @include transition();
      .anim.loading {
        border-color: darken($color_error, 21%);
        border-bottom-color: lighten($color_error, 10%); } } }


  &.disabled {
    cursor: not-allowed;
    border: solid 1px darken($color_gray, 15%);
    background: #E4EDF4;
    color: lighten($color_font, 20%);
    &:hover,
    &:active,
    &:focus {
      color: lighten($color_font, 20%);
      border: solid 1px darken($color_gray, 15%);
      cursor: not-allowed;
      background: #E4EDF4;
      .anim.loading {
        display: none; } } }
  &.upload {
    padding-left: 30px;
    background: $color_yellow;
    border: solid 1px darken($color_yellow, 15%);
    color: $color_white;
    &:before {
      position: absolute;
      left: 5px;
      font-size: 20px;
      content: 'U';
      color: $color_font;
      font-family: 'icon';
      top: 2px;
      content: none; }
    &.finished {
      background: $color_success;
      color: $color_white;
      &:before {
        display: none; } }
    .finished {
      display: block;
      width: 20px;
      height: 20px;
      color: $color_white;
      position: absolute;
      top: 5px;
      font-size: 18px;
      right: 5px;
      -webkit-animation: popIn 0.5s 1 ease;
      -moz-animation: popIn 0.5s 1 ease;
      animation: popIn 0.5s 1 ease;
      &:before {
        font-family: 'icon';
        content: 'w';
        color: $color_white; } } }
  &.default {
    background: darken($color_gray, 20%);
    color: $color_white;
    border-color: darken($color_gray, 25%);
    &:hover,
    &.hover {
      background: darken($color_gray, 30%);
      border-color: darken($color_gray, 35%); } }

  &.edit {
    position: absolute;
    top: 23px;
    right: 40px;
    padding: 5px 10px;
    height: 30px;
    line-height: 20px;
    background: transparent;
    color: $color_highlight;
    border-radius: 6px;
    width: auto !important;
    border: none;
    margin: 0 !important;
    min-width: 80px;
    font-size: 14px;
    &:hover {
      color: $color_white;
      background: $color_highlight; } } }

.btn {
  overflow: hidden;
  &:hover {
    text-decoration: none; }
  input {
    position: absolute !important;
    text-align: left !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    padding: 10px !important;
    box-sizing: border-box !important;
    font-size: inherit !important;
    color: inherit !important;
    background: transparent !important;
    border: 0 !important;
    outline: 0 !important; } }

//############################################################################################################Animations

.anim.loading {
  display: block;
  font-size: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 100px;
  width: $font-size;
  height: $font-size;
  text-indent: -9999em;
  border: solid 3px $color_highlight;
  -webkit-animation: loader 1.1s 4 linear;
  -moz-animation: loader 1.1s 4 linear;
  animation: loader 1.1s 4 linear; }

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg); }

  100% {
    -webkit-transform: rotate(360deg); } }

@-moz-keyframes loader {
  0% {
    -moz-transform: rotate(0deg); }

  100% {
    -moz-transform: rotate(360deg); } }

@keyframes loader {
  0% {
    transform: rotate(0deg); }

  100% {
    transform: rotate(360deg); } }




@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1,1); }
  50% {
    -webkit-transform: scale(1.05,1.05); }
  100% {
    -webkit-transform: scale(1,1); } }

@-moz-keyframes ripple {
  0% {
    -moz-transform: scale(1,1); }
  50% {
    -moz-transform: scale(1.05,1.05); }
  100% {
    -moz-transform: scale(1,1); } }

@keyframes ripple {
  0% {
    transform: scale(1,1); }
  50% {
    transform: scale(1.05,1.05); }
  100% {
    transform: scale(1,1); } }
